import ButtonOrLink, { Props as ButtonOrLinkProps } from "./ButtonOrLink";
import { cva, VariantProps } from "class-variance-authority";

export const buttonStyles = cva(
  "flex items-center justify-center font-semibold duration-200 transition ring-2 ring-inset ring-transparent focus:outline-none disabled:opacity-90 disabled:pointer-events-none",
  {
    variants: {
      importance: {
        premium:
          "bg-gradient-to-b from-[#131619] via-[#0F1215] to-[#0E1114] text-white/90 !text-base !ring-0 hover:from-[#1A1D21] hover:via-[#15171C] hover:to-[#13161B] focus:from-[#1A1D21] focus:via-[#15171C] focus:to-[#13161B] disabled:bg-none disabled:bg-[#111418] disabled:text-white/50",
        primary:
          "bg-gradient-to-b from-[#3377FF] to-[#0055FF] shadow-btn text-white hover:from-[#4C86FF] hover:to-[#1F69FF] focus:ring-white/40 disabled:text-white/50 disabled:bg-none disabled:bg-[#1D222A]/[.85]",
        secondary:
          "bg-gradient-to-b from-[#14191D] via-[#11161A] to-[#0F1418] text-white/90 shadow-btn-dark hover:from-[#1A1D21] hover:via-[#15171C] hover:to-[#13161B] focus:from-[#1A1D21] focus:via-[#15171C] focus:to-[#13161B] focus:ring-white/20 disabled:bg-none disabled:;bg-[#111418] disabled:text-white/50 disabled:shadow-btn-dark-inactive",
        space:
          "bg-gradient-to-r from-white/03 bg-[#0C0F12] hover:bg-[#13161B] text-white/90 ring-white/[.24] ",
        clear: "bg-transparent",
      },
      size: {
        large: "py-4 px-6 text-sm rounded-2xl",
        medium: "py-3 px-6 text-sm rounded-xl h-12",
        small: "py-2.5 px-4 text-sm rounded-[10px]",
        xsmall: "py-1 px-3 text-sm text-[0.8125rem] rounded-lg",
      },
      fullWidth: {
        true: "w-full",
      },
    },
    defaultVariants: {
      importance: "primary",
      size: "medium",
    },
  }
);

export interface Props extends ButtonOrLinkProps, VariantProps<typeof buttonStyles> {}

export default function Button({ className, importance, size, fullWidth, ...props }: Props) {
  return (
    <ButtonOrLink className={buttonStyles({ className, importance, size, fullWidth })} {...props} />
  );
}
