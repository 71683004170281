import { Fragment } from "react";
import { Switch as BaseSwitch } from "@headlessui/react";

export default function Switch({
  label,
  name = "",
  className = "",
  small = false,
  checked = undefined,
  onChange = undefined,
  defaultChecked = false,
}: {
  label: string;
  name?: string;
  className?: string;
  small?: boolean;
  checked?: boolean | undefined;
  onChange?: (checked: boolean) => void | undefined;
  defaultChecked?: boolean | undefined;
}) {
  return (
    <BaseSwitch
      name={name}
      checked={checked}
      onChange={onChange}
      defaultChecked={defaultChecked}
      className={`
        ${className}
        ${small ? "h-[18px] w-[28px] p-0.5" : "h-[30px] w-[48px] p-[3px]"}
        relative inline-flex shrink-0 cursor-pointer items-center rounded-full bg-white/[.08] shadow-switch transition-colors duration-200 ease-in-out hover:bg-white/20 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-40
        ui-checked:bg-gradient-to-b ui-checked:from-[#1772FF] ui-checked:to-[#004DFF]
        ui-checked:hover:from-[#3883FF] ui-checked:hover:to-[#0062FC]
      `}
    >
      <span className="sr-only">{label}</span>
      <span
        aria-hidden="true"
        className={`${
          small ? "h-3.5 w-3.5 ui-checked:translate-x-2.5" : "h-6 w-6 ui-checked:translate-x-[18px]"
        } pointer-events-none inline-flex translate-x-0 rounded-full ring-0 transition duration-200 ease-in-out`}
        style={{
          background: small
            ? "radial-gradient(145.83% 83.33% at 50% 37.5%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.128) 100%), conic-gradient(from 128.66deg at 50% 50%, #FFFFFF -0.28deg, rgba(255, 255, 255, 0.8) 35.99deg, rgba(255, 255, 255, 0.2) 57.9deg, rgba(255, 255, 255, 0) 90deg, rgba(255, 255, 255, 0.2) 114.78deg, rgba(255, 255, 255, 0.8) 140.55deg, #FFFFFF 180deg, rgba(255, 255, 255, 0.8) 213.39deg, rgba(255, 255, 255, 0.2) 237deg, rgba(255, 255, 255, 0) 269.43deg, rgba(255, 255, 255, 0.2) 300.96deg, rgba(255, 255, 255, 0.8) 324.11deg, #FFFFFF 359.72deg, rgba(255, 255, 255, 0.8) 395.99deg), #666666"
            : "radial-gradient(145.83% 83.33% at 50% 37.5%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.128) 100%), conic-gradient(from 128.66deg at 50% 50%, #FFFFFF -0.28deg, rgba(255, 255, 255, 0.8) 35.99deg, rgba(255, 255, 255, 0.2) 57.9deg, rgba(255, 255, 255, 0) 90deg, rgba(255, 255, 255, 0.2) 114.78deg, rgba(255, 255, 255, 0.8) 140.55deg, #FFFFFF 180deg, rgba(255, 255, 255, 0.8) 213.39deg, rgba(255, 255, 255, 0.2) 237deg, rgba(255, 255, 255, 0) 269.43deg, rgba(255, 255, 255, 0.2) 300.96deg, rgba(255, 255, 255, 0.8) 324.11deg, #FFFFFF 359.72deg, rgba(255, 255, 255, 0.8) 395.99deg), #505050",
          boxShadow: small
            ? "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.08), inset 0px 1px 0px rgba(255, 255, 255, 0.32), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.12)"
            : "0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(0, 0, 0, 0.08), inset 0px 1px 0px rgba(255, 255, 255, 0.32), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.12)",
        }}
      />
    </BaseSwitch>
  );
}
