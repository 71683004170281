import React from "react";

import ButtonOrLink from "components/Button/ButtonOrLink";
import PremiumBadge from "components/PremiumBadge/PremiumBadge";
import Dialog from "components/Dialog/Dialog";
import Listen from "components/Listen";
import Explicit from "public/assets/explicit.svg";
import { PlaybackOption } from "gql/graphql";
import { Trans } from "next-i18next";

const ShowDetailsDialog = ({
  show,
  productName = "",
  open,
  onClose,
}: {
  show: {
    title: string;
    productTitle: string;
    coverImageUrl: string;
    details: string;
    premium: boolean;
    explicit: boolean;
    playbackOptions: PlaybackOption[];
  } | null;
  productName: string | "";
  open: boolean;
  onClose: Function;
}) => {
  return (
    <Dialog
      show={open}
      title={show?.title}
      image={show?.coverImageUrl}
      smallImage
      onClose={() => onClose()}
    >
      <div>
        <div className="mt-3 flex h-5 items-center justify-center gap-3 overflow-hidden text-[13px]">
          {show?.premium && <PremiumBadge small />}
          <p className="whitepsace-nowrap inline truncate text-white/70">{show?.details}</p>
          {show?.explicit && (
            <Explicit className="inline-block fill-white/70 pt-[1px] align-baseline" />
          )}
        </div>
        <p className="mt-6 text-white/70">
          <Trans i18nKey="details_description">
            Your <span className="text-white/90">{show?.productTitle}</span> subscription unlocks{" "}
            <span className="text-white/90">{show?.title}</span>, listen in your favorite podcast
            app
          </Trans>
        </p>
        <div className="mt-8">
          <Listen playbackOptions={show?.playbackOptions} />
        </div>
        <p className="mt-8 text-xs text-white/70">
          <Trans i18nKey="contact_support_team">
            Please contact our{" "}
            <ButtonOrLink
              href="mailto:help@podspace.com"
              className="rounded text-white/90 underline focus:shadow-input-focus focus:outline-none focus:ring-0"
            >
              Support Team
            </ButtonOrLink>{" "}
            for any questions.
          </Trans>
        </p>
      </div>
    </Dialog>
  );
};

export default ShowDetailsDialog;
