import { ComponentProps, useEffect } from "react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

interface Props extends ComponentProps<"div"> {
  show: boolean;
  onClose: Function;
}

export default function Toast({ children, show, onClose }: Props) {
  let timeout: string | number | Timeout | undefined;

  const close = () => {
    onClose();
    clearTimeout(timeout);
  };

  useEffect(() => {
    if (show) {
      timeout = setTimeout(() => {
        onClose();
      }, 3000);
    }
  });

  return (
    <div className="fixed inset-x-0 bottom-4 z-[100]">
      <Transition
        show={show}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
        appear
      >
        <div className="mx-auto w-full max-w-2xl rounded-md bg-comp-yellow-600 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-black/80" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <div className="text-sm font-medium text-black">{children}</div>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="focus:btn-shadow-focus inline-flex rounded-md p-1.5 text-black transition hover:text-black/80 focus:outline-none focus:ring-0"
                  onClick={() => close()}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
