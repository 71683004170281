import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import { useInViewport } from "react-in-viewport";

import Image from "next/image";
import { Dialog as BaseDialog, Transition } from "@headlessui/react";
import Button from "components/Button/Button";

const Dialog = ({
  show,
  onClose,
  title,
  description = "",
  image,
  small = false,
  smallImage = false,
  children = "",
}: {
  show: boolean;
  onClose: Function | undefined;
  title: string | undefined;
  description?: JSX.Element | string | undefined;
  image: string | undefined;
  small?: boolean | null;
  smallImage?: boolean | null;
  children: JSX.Element | JSX.Element[] | string;
}) => {
  const buttonHeaderRef = useRef(null);
  const { inViewport: buttonHeaderInViewport } = useInViewport(buttonHeaderRef, {
    rootMargin: "0px 0px 20px 0px",
  });

  const [showFloatingButtonHeader, setShowFloatingButtonHeader] = useState(false);

  useEffect(() => {
    setShowFloatingButtonHeader(show);
  });

  return (
    <Transition show={show} as={Fragment}>
      <BaseDialog onClose={() => onClose?.()} className="absolute inset-0 z-50 sm:py-16">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/90" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center sm:items-center sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <BaseDialog.Panel
                className={`relative min-h-screen w-full bg-surface-700 bg-target-pattern bg-contain bg-no-repeat sm:mx-auto sm:min-h-0 sm:rounded-3xl sm:shadow-modal ${
                  small ? "sm:max-w-[400px]" : " sm:max-w-[480px]"
                }`}
              >
                <div
                  className={`absolute inset-x-0 top-0 flex w-full justify-start px-3 py-4 ${
                    small ? "sm:p-6" : "sm:p-8"
                  }`}
                  ref={buttonHeaderRef}
                >
                  <button
                    hidden={onClose === undefined}
                    onClick={() => onClose?.()}
                    className="rounded-md p-1 text-white transition hover:text-white/75 focus-visible:text-white/75 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white/10"
                  >
                    <svg
                      className="w-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.655 6.345c.165.164.247.247.285.339a.5.5 0 0 1 0 .382c-.038.092-.12.175-.285.34l-4.312 4.311c-.098.1-.148.149-.167.206a.25.25 0 0 0 0 .154c.019.057.069.107.168.206l4.311 4.312c.165.164.247.247.285.339a.5.5 0 0 1 0 .382c-.038.092-.12.175-.285.34-.164.164-.247.247-.339.285a.5.5 0 0 1-.382 0c-.092-.038-.175-.12-.34-.286l-4.311-4.312c-.1-.098-.149-.148-.206-.166a.25.25 0 0 0-.154 0c-.057.018-.107.068-.206.167l-4.312 4.311c-.164.165-.247.247-.339.285a.5.5 0 0 1-.382 0c-.092-.038-.175-.12-.34-.285-.164-.164-.246-.247-.284-.339a.5.5 0 0 1 0-.382c.038-.092.12-.175.285-.34l4.311-4.311c.1-.1.149-.149.168-.206a.25.25 0 0 0 0-.154c-.019-.057-.069-.107-.168-.206L6.345 7.405c-.165-.164-.247-.247-.285-.339a.5.5 0 0 1 0-.382c.038-.092.12-.175.285-.34.164-.164.247-.246.339-.284a.5.5 0 0 1 .382 0c.092.038.175.12.34.285l4.311 4.311c.1.1.149.149.206.168a.25.25 0 0 0 .154 0c.057-.019.107-.069.206-.168l4.312-4.311c.164-.165.247-.247.339-.285a.5.5 0 0 1 .382 0c.092.038.175.12.34.285Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={`pointer-events-none sticky inset-x-0 top-0 flex w-full justify-start px-3 py-4 ${
                    small ? "sm:p-6" : "sm:p-8"
                  }`}
                >
                  <Button
                    importance="secondary"
                    onClick={() => onClose?.()}
                    className={`pointer-events-auto h-auto origin-center !rounded-full !p-1 transition ease-bounce ${
                      showFloatingButtonHeader && !buttonHeaderInViewport
                        ? "opactity-100 translate-y-0"
                        : "-translate-y-full opacity-0"
                    }`}
                    tabIndex={-1}
                  >
                    <svg
                      className="w-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.655 6.345c.165.164.247.247.285.339a.5.5 0 0 1 0 .382c-.038.092-.12.175-.285.34l-4.312 4.311c-.098.1-.148.149-.167.206a.25.25 0 0 0 0 .154c.019.057.069.107.168.206l4.311 4.312c.165.164.247.247.285.339a.5.5 0 0 1 0 .382c-.038.092-.12.175-.285.34-.164.164-.247.247-.339.285a.5.5 0 0 1-.382 0c-.092-.038-.175-.12-.34-.286l-4.311-4.312c-.1-.098-.149-.148-.206-.166a.25.25 0 0 0-.154 0c-.057.018-.107.068-.206.167l-4.312 4.311c-.164.165-.247.247-.339.285a.5.5 0 0 1-.382 0c-.092-.038-.175-.12-.34-.285-.164-.164-.246-.247-.284-.339a.5.5 0 0 1 0-.382c.038-.092.12-.175.285-.34l4.311-4.311c.1-.1.149-.149.168-.206a.25.25 0 0 0 0-.154c-.019-.057-.069-.107-.168-.206L6.345 7.405c-.165-.164-.247-.247-.285-.339a.5.5 0 0 1 0-.382c.038-.092.12-.175.285-.34.164-.164.247-.246.339-.284a.5.5 0 0 1 .382 0c.092.038.175.12.34.285l4.311 4.311c.1.1.149.149.206.168a.25.25 0 0 0 .154 0c.057-.019.107-.069.206-.168l4.312-4.311c.164-.165.247-.247.339-.285a.5.5 0 0 1 .382 0c.092.038.175.12.34.285Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Button>
                </div>
                <div
                  className={`mx-auto -mt-10 max-w-md px-4 pb-5 text-white sm:-mt-14 sm:max-w-full ${
                    small ? "sm:px-8 sm:pb-8" : "sm:px-12 sm:pb-12"
                  }`}
                >
                  <div
                    className={
                      small || smallImage ? "mx-auto h-32 w-32" : "mx-auto h-[196px] w-[196px]"
                    }
                  >
                    <Image
                      className={small || smallImage ? "h-32 w-32" : "h-[196px] w-[196px]"}
                      src={image ? image : ""}
                      width={small ? 128 : 196}
                      height={small ? 128 : 196}
                      quality={100}
                    />
                  </div>
                  <BaseDialog.Title className="mt-4 font-display text-[32px] font-bold leading-none">
                    {title}
                  </BaseDialog.Title>
                  {description && (
                    <BaseDialog.Description as="p" className="mt-3 text-center text-white/70">
                      {description}
                    </BaseDialog.Description>
                  )}

                  {children}
                </div>
              </BaseDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </BaseDialog>
    </Transition>
  );
};

export default Dialog;
