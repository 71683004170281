import { RadioGroup as BaseRadioGroup } from "@headlessui/react";

export default function RadioGroup({
  children,
  value,
  defaultValue = null,
  onChange,
  className,
  name = "",
  label = null,
  ...props
}: {
  children: React.ReactNode;
  value?: string | number | object | null | undefined;
  defaultValue?: string | number | object | null | undefined;
  onChange?: ((value: string | number | object) => void) | undefined;
  className?: string | undefined;
  label?: string | null | undefined;
  name?: string | undefined;
}) {
  return (
    <BaseRadioGroup
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      className={className}
      {...props}
    >
      {label && (
        <BaseRadioGroup.Label className="flex items-center gap-4 text-sm font-semibold text-white lg:text-base">
          <span>Select plan</span>
          <span className="h-px w-full flex-1 bg-white/[.08]"></span>
        </BaseRadioGroup.Label>
      )}
      <div className={`${label && "mt-6"} space-y-2`}>{children}</div>
    </BaseRadioGroup>
  );
}

export function RadioGroupItem({
  children,
  className,
  value,
  indicator = true,
  theme = "dark/white",
}: {
  children: React.ReactNode;
  id: string;
  className?: string;
  value: string | number | object;
  indicator?: boolean;
  theme?: "dark/white" | "dark/blue";
}) {
  return (
    <BaseRadioGroup.Option
      className={`group flex rounded-2xl border border-white/[.08] py-3 pl-5 pr-4 font-semibold ring-2 ring-transparent transition duration-300 hover:cursor-pointer hover:border-white/20 hover:!text-white focus:outline-none ui-checked:!border-transparent ui-checked:bg-surface-700 ui-checked:text-white/90 ${className} ${
        {
          "dark/white": "  text-white/70 ui-checked:ring-white/90",
          "dark/blue": "text-white/90 ui-checked:shadow-input-focus ui-checked:ring-transparent",
        }[theme]
      }`}
      value={value}
    >
      {indicator && (
        <div
          className={`my-1 mr-5 flex h-4 w-4 shrink-0 items-center justify-center rounded-full ring-1 ring-inset ring-white/10 transition duration-300 group-hover:ring-white/50 ui-checked:bg-[#0657FA] ui-checked:bg-gradient-to-br ui-checked:!ring-white/[.05]`}
          aria-hidden="true"
        >
          <span className="flex h-1.5 w-1.5 items-center justify-center rounded-full bg-white opacity-0 transition duration-300 ui-checked:opacity-100" />
        </div>
      )}
      <div className="flex flex-1 items-center">{children}</div>
    </BaseRadioGroup.Option>
  );
}
