import { useTranslation } from "next-i18next";

export default function PremiumBadge({ small = false }: { small?: boolean }) {
  const { t } = useTranslation("common");

  return (
    <div className={`relative shrink-0 overflow-hidden rounded ${small ? "-mt-0.5" : "-mt-1"}`}>
      <span
        className={`relative inline-flex items-center rounded bg-white px-1 font-[800] uppercase tracking-wider text-surface-800 ${
          small ? "text-[8px] leading-3" : "text-[10px] leading-4"
        }`}
        style={{ textShadow: "0px 1px 0.5px rgba(253, 255, 255, 0.16)" }}
      >
        <span
          className="pointer-events-none absolute top-0 -left-8 h-8 w-8 -translate-y-[.375rem] animate-slide"
          style={{
            backgroundImage:
              "radial-gradient(38.1% 92.19% at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%)",
            mixBlendMode: "darken",
          }}
        ></span>
        <span
          className={`pointer-events-none absolute inset-0 z-10 rounded border border-white/20 ${
            small ? "h-3" : "h-4"
          }`}
        ></span>
        <span className="relative">{t("premium_badge")}</span>
      </span>
    </div>
  );
}
