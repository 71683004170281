import Image from "next/image";

export default function CheckedListItem({
  children,
}: {
  children: JSX.Element | JSX.Element[] | string;
}) {
  return (
    <li className="flex items-start gap-4">
      <Image
        src="/assets/check-circle-white.svg"
        aria-hidden="true"
        width={24}
        height={24}
        alt="checked bullet"
        quality={100}
      />
      <span className="flex-1">{children}</span>
    </li>
  );
}
