import Image from "next/image";

type Props = {
  children: React.ReactNode;
  title: string;
  className?: string;
  header?: React.ReactNode;
  importance: "premium" | "default";
};

const Card = ({ children, title, importance = "default", className = "", header }: Props) => (
  <div
    className={`premium-card-wrapper min-h-fit overflow-hidden rounded-3xl bg-surface-800 pt-5 pb-6 text-white/90 shadow-card md:py-6 lg:rounded-[32px] lg:py-8 ${className}`}
  >
    {importance === "default" && header}
    {importance === "premium" && (
      <>
        <div className="bg-premium-card-gradient absolute inset-0 h-[400px] w-full"></div>
        <div className="relative flex items-center px-6 lg:px-8">
          <Image
            src="/assets/podspace-premium-colorized.png"
            className=""
            width="24"
            height="24"
            quality={100}
          />
          <span className="ml-3 text-xs font-[650] uppercase text-white/70">{title}</span>
        </div>
      </>
    )}
    <div className="relative px-6 pt-7 lg:px-12 lg:pt-10">{children}</div>
  </div>
);

export default Card;
