import { format, formatDistanceToNow, isAfter, subDays } from "date-fns";
import { sv } from "date-fns/locale";
export const formatRelativeDate = (string, locale) => {
  let date = new Date(string);

  if (isAfter(date, subDays(new Date(), 7))) {
    return formatDistanceToNow(date, {
      addSuffix: true,
      locale: locale === "sv" ? sv : undefined,
    });
  }

  let formatString = isAfter(date, new Date(`${new Date().getFullYear() - 1}-12-31`))
    ? "d MMMM"
    : "d MMMM Y";

  if (locale === "sv") {
    return format(new Date(date), formatString, { locale: sv });
  } else {
    return format(new Date(date), formatString);
  }
};

export const formatPlayerEpisodeTime = (time, duration) => {
  const isMoreThanAnHours = duration / 60 / 60 > 1;
  if (time && !isNaN(time)) {
    let hours = 0;
    let minutes = Math.floor(time / 60);
    if (minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes = minutes - hours * 60;
    }
    let formatHours =
      hours > 0 ? (hours < 10 ? `0${hours}:` : `${hours}:`) : isMoreThanAnHours ? "00:" : "";
    let formatMinutes = minutes < 10 ? `0${minutes}:` : `${minutes}:`;
    let seconds = Math.floor(time % 60);
    let formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatHours}${formatMinutes}${formatSeconds}`;
  }
  if (isMoreThanAnHours) {
    return "00:00:00";
  } else {
    return "00:00";
  }
};

export const formatEpisodeDuration = (duration) => {
  let [hours, minutes, seconds] = duration.split(":");

  if (!seconds) {
    minutes = hours;
    hours = 0;
  }
  return `${hours > 0 ? +hours + "h" : ""} ${minutes + "m"}`;
};
