import React, { useState } from "react";

import ButtonOrLink from "./Button/ButtonOrLink";
import CopyToClipboardButton from "./Button/CopyToClipboardButton";
import Image from "next/image";
import LinkExternal from "/public/assets/link-external.svg";
import { PlaybackOption } from "gql/graphql";
import Copy from "/public/assets/copy.svg";

const Listen = ({ playbackOptions }: { playbackOptions: PlaybackOption[] | undefined }) => {
  const filteredPlaybackOptions = playbackOptions?.filter(
    ({ name }) => name !== "Personal RSS-Feed"
  );
  const rssPlaybackOption = playbackOptions?.find(({ name }) => name === "Personal RSS-Feed");
  const [rssExpanded, setRssExpanded] = useState(false);

  return (
    <section>
      <h2 className="flex items-center gap-4 text-sm font-semibold text-white lg:text-base">
        <span>Listen</span>
        <span className="h-px w-full flex-1 bg-white/[.08]"></span>
      </h2>
      <ul className="mt-6">
        {filteredPlaybackOptions?.map((option, i) => {
          return (
            <li key={i}>
              <ButtonOrLink
                href={option.url}
                className="group flex items-center gap-4 rounded focus:shadow-input-focus focus:outline-none focus:ring-0"
              >
                <span className="inline-flex shrink-0 items-center">
                  <span className="sr-only">${option.name}</span>
                  <Image
                    src={`/assets/${option.icon}`}
                    width="24"
                    height="24"
                    className="inline-block"
                    aria-hidden="true"
                    quality={100}
                  />
                </span>
                <div
                  className={`flex flex-1 items-center justify-between gap-4 py-2.5 ${
                    i < filteredPlaybackOptions?.length - 1 && "border-b border-white/10"
                  }`}
                >
                  <span className="font-semibold">{option.name}</span>
                  <span className="inline-flex items-center rounded text-xs font-semibold text-white/50 transition group-hover:text-white/70">
                    <span>Play now</span>
                    <LinkExternal className="ml-2 h-6 w-6 fill-current" />
                  </span>
                </div>
              </ButtonOrLink>
            </li>
          );
        })}
        {rssPlaybackOption && (
          <div className="mt-8 space-y-3 text-left">
            <div className="text-sm font-semibold text-white">{rssPlaybackOption.name}</div>
            <div
              className={`flex items-center justify-between gap-3 overflow-hidden rounded-xl bg-surface-800 py-2 pl-4 pr-2 text-sm font-medium text-white/90 shadow-input-inactive transition-all`}
            >
              <span className={!rssExpanded ? "truncate" : ""} onClick={() => setRssExpanded(true)}>
                {rssPlaybackOption.url}
              </span>
              {!rssExpanded && (
                <CopyToClipboardButton
                  text={rssPlaybackOption.url}
                  className="group flex items-center gap-2 text-xs font-semibold text-white/50 transition hover:text-white/70"
                >
                  <span>Copy</span>
                  <Copy className="fill-white/50 transition group-hover:fill-white/70" />
                </CopyToClipboardButton>
              )}
            </div>
            <p className="text-xs text-white/70">
              If you wish to use a podcast player other than the ones listed above, simply copy the
              RSS-feed and paste it into your preferred player.
            </p>
          </div>
        )}
      </ul>
    </section>
  );
};

export default Listen;
