import { useState } from "react";

import { Transition } from "@headlessui/react";

export default function TooltipOnHover({
  children,
  text,
  className,
  ...props
}: {
  children: React.ReactNode;
  text: string;
  className: string;
}) {
  const [show, setShow] = useState(false);

  return (
    <div
      className={`relative ${className}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      <Transition
        show={show}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0"
      >
        <span className="absolute -top-1 left-1/2 block origin-bottom -translate-y-14 -translate-x-1/2 whitespace-nowrap rounded border border-white/10 bg-[#131619] px-2 py-1 text-xs">
          {text}
        </span>
      </Transition>
    </div>
  );
}
