import { Disclosure } from "@headlessui/react";
import { ReactNode } from "react";
import ChevronDown from "../../public/assets/chevron-down.svg";

type btn = {
  children: ReactNode;
  className?: string;
};

export const DisclosureButton = ({ children, className = "" }: btn): JSX.Element => (
  <Disclosure.Button
    className={`group flex w-full items-center justify-between overflow-hidden rounded-lg px-2 py-2 text-left text-xl font-semibold transition focus:outline-none focus-visible:shadow-input-focus ${className}`}
  >
    <span className="flex-1 truncate">{children}</span>
    <ChevronDown className="fill-white/50 transition group-hover:fill-white/70 ui-open:rotate-180 ui-open:transform" />
  </Disclosure.Button>
);

export default Disclosure;
