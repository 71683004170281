import Image from "next/image";
import { useTranslation } from "next-i18next";
import strftime from "strftime";
import {
  PageQuery,
  Get_SubscriptionQuery,
  SubscriptionStatus,
  BillingFrequency,
} from "gql/graphql";

import Button from "./Button/Button";
import ButtonOrLink from "components/Button/ButtonOrLink";
import Copy from "public/assets/copy.svg";
import ChevronRight from "public/assets/chevron-right.svg";
import CopyToClipboardButton from "./Button/CopyToClipboardButton";
import Explicit from "public/assets/explicit.svg";
import Listen from "components/Listen";
import Lock from "public/assets/lock.svg";
import Stripe from "public/assets/stripe.svg";

export default function SubscriptionDetails({
  subscriptionData,
  data,
  multipleShows,
  displayShowDetailsDialog,
}: {
  subscriptionData: Get_SubscriptionQuery | undefined;
  data: PageQuery;
  multipleShows: boolean;
  displayShowDetailsDialog: (show: any) => void;
}) {
  const { t } = useTranslation("common");

  return (
    <>
      <h2 className="font-display text-[32px] font-bold leading-none lg:text-[40px]">
        {t("subscribed_header")}
      </h2>

      <p className="mt-6 text-white/70">
        {t("subscribed_subheader1")}{" "}
        <span className="text-white/90">{subscriptionData?.subscription?.product.name}</span>
      </p>
      <p className="mt-2 text-white/70">{t("subscribed_subheader2")}</p>

      {!multipleShows && (
        <div className="mt-8">
          <Listen
            playbackOptions={subscriptionData!.subscription?.product.shows[0].playbackOptions}
          />
        </div>
      )}
      {multipleShows && (
        <section className="mt-11">
          <h2 className="flex items-center gap-4 text-sm font-semibold text-white lg:text-base">
            <span>{t("shows")}</span>
            <span className="h-px w-full flex-1 bg-white/[.08]"></span>
          </h2>
          <ul className="mt-6 space-y-5">
            {subscriptionData?.subscription?.product.shows.map((show, i) => (
              <li key={i}>
                <ButtonOrLink
                  onClick={() =>
                    displayShowDetailsDialog({
                      title: show.title,
                      productTitle: data?.page.hero.title,
                      art: show.coverImageUrl,
                      details: data?.page.hero.metadata.join("・"),
                      premium: true,
                      explicit: show.explicit,
                      playbackOptions: show.playbackOptions,
                    })
                  }
                  className="group flex w-full items-center gap-4 rounded focus:shadow-input-focus focus:outline-none focus:ring-0"
                >
                  <span className="h-14 w-14 shrink-0">
                    <Image
                      className="w-l4 h-14 rounded"
                      src={show.coverImageUrl}
                      width="56"
                      height="56"
                      quality={100}
                    />
                  </span>
                  <div className="flex-1 overflow-hidden">
                    <div className="flex items-center justify-between gap-4">
                      <span className="font-semibold">{show.title}</span>
                      <ChevronRight className="h-6 w-6 fill-white/50 transition group-hover:fill-white/90" />
                    </div>
                    <p className="mt-0.5 flex text-[13px] font-medium text-white/70 md:text-sm">
                      <span className="truncate">{show.subtitle}</span>
                      {show.explicit && (
                        <Explicit className="inline-block shrink-0 fill-white/70 pt-[1px] align-baseline" />
                      )}
                    </p>
                  </div>
                </ButtonOrLink>
              </li>
            ))}
          </ul>
        </section>
      )}

      <section className="mt-11">
        <h2 className="flex items-center gap-4 text-sm font-semibold text-white lg:text-base">
          <span className="capitalize">{t("subscription")}</span>
          <span className="h-px w-full flex-1 bg-white/[.08]"></span>
        </h2>
        {subscriptionData?.subscription?.status === SubscriptionStatus.InTrial && (
          <div className="mt-6 border-b border-white/10 py-3">
            <div className="flex justify-between font-semibold leading-[24px] text-white">
              <span>
                {t("days_free_trial", {
                  days: subscriptionData?.subscription.purchaseOption.trialDays,
                })}
              </span>
              <span className="font-medium">
                {t("free")} <span className="text-white/70">(0,00 kr)</span>
              </span>
            </div>
            <div className="text-[13px] leading-[24px] text-comp-yellow-600">
              {t("ending")}{" "}
              {strftime("%B %d, %Y", new Date(subscriptionData?.subscription.trialPeriodEndsAt))}
            </div>
          </div>
        )}
        <div className="border-b border-white/10 py-3">
          <div className="flex justify-between font-semibold leading-[24px] text-white">
            {subscriptionData?.subscription?.purchaseOption.billingFrequency ===
            BillingFrequency.Monthly ? (
              <span>
                {t("Monthly")} {t("subscription")}
              </span>
            ) : (
              <span>
                {t("Yearly")} {t("subscription")}
              </span>
            )}
            <span className="font-medium">
              {`${t("displayPrice", {
                price: subscriptionData?.subscription?.purchaseOption.price.price,
              })}`}{" "}
              kr
            </span>
          </div>
          <div className="text-[13px] leading-[24px] text-white/50">
            {subscriptionData?.subscription?.status === SubscriptionStatus.InTrial && (
              <>
                {t("starting")}{" "}
                {strftime("%B %d, %Y", new Date(subscriptionData?.subscription.nextBillingAt))}
              </>
            )}

            {subscriptionData?.subscription?.status === SubscriptionStatus.Active && (
              <>
                {t("next_billing")}{" "}
                {strftime("%B %d, %Y", new Date(subscriptionData?.subscription.nextBillingAt))}
              </>
            )}
          </div>
        </div>
        <div className="border-b border-white/10 py-2">
          <CopyToClipboardButton
            text={subscriptionData?.subscription?.reference}
            className="group w-full rounded text-left focus:shadow-input-focus focus:outline-none focus:ring-0"
          >
            <div className="flex items-center justify-between font-semibold text-white">
              <span className="leading-[24px]">{subscriptionData?.subscription?.reference}</span>
              <span className="inline-flex font-medium">
                <span className="sr-only">View</span>
                <Copy
                  className="h-6 w-6 fill-white/50 transition group-hover:fill-white/70"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="text-[13px] leading-[24px] text-white/50">{t("reference_code")}</div>
          </CopyToClipboardButton>
        </div>
        <p className="mt-6 text-xs text-white/70">
          {t("renewal_message", {
            date: strftime("%B %d, %Y", new Date(subscriptionData?.subscription.nextBillingAt)),
          })}
        </p>
        <p className="mt-2 text-xs text-white/70">
          <ButtonOrLink
            href={subscriptionData?.subscription?.manageUrl}
            className="rounded text-white underline transition hover:text-white/70 focus:shadow-input-focus focus:outline-none focus:ring-0"
          >
            {t("cancel_subscription")}
          </ButtonOrLink>{" "}
          {t("cancel_hint")}
        </p>
        <p className="mt-2 text-xs text-white/70">
          {t("If you have any questions please contact our")}{" "}
          <ButtonOrLink
            href="mailto:help@podspace.com"
            className="rounded text-white underline transition hover:text-white/70 focus:shadow-input-focus focus:outline-none focus:ring-0"
          >
            {t("customer_support")}
          </ButtonOrLink>{" "}
          {t("or find answers in our")}{" "}
          <ButtonOrLink
            href="#faq"
            className="rounded text-white underline transition hover:text-white/70 focus:shadow-input-focus focus:outline-none focus:ring-0"
            smoothScroll
          >
            {t("faq")}
          </ButtonOrLink>
          .
        </p>
      </section>
      <div className="mt-11">
        <p className="text-center text-xs text-white/70">
          <Lock title="secure payments" className="mr-2 inline-block fill-white/70" />
          {t("secure_payments")}
          <Stripe title="stripe" className="ml-[6px] inline-block" />
        </p>
        <div className="mt-10 flex justify-center">
          <Button
            className="w-full lg:mx-auto lg:w-auto"
            size="small"
            importance="secondary"
            href={subscriptionData?.subscription?.manageUrl}
          >
            {t("manage_subscription")}
          </Button>
        </div>
      </div>
    </>
  );
}
