import React, { createRef, forwardRef, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

type Props = {
  name?: string;
  value?: string;
  onChange?: any;
  onKeyDown?: any;
  type?: string;
  disabled?: boolean;
  label?: string;
  small?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  error?: string;
  info?: React.ReactNode | string;
  theme?: "dark" | "light";
};

const Input = forwardRef(
  (
    {
      name,
      value = "",
      onChange = () => {},
      onKeyDown = () => {},
      type = "text",
      label = "",
      small = false,
      placeholder = "",
      icon = null,
      rightIcon = null,
      error = "",
      info = "",
      disabled = false,
      theme = "dark",
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    let inputRef = ref;
    if (!ref) {
      inputRef = createRef<HTMLInputElement>();
    }

    return (
      <div className="space-y-3 text-left">
        {label ? (
          <div className="flex items-center gap-2">
            <label htmlFor={name} className="text-sm font-semibold text-white">
              {label}
            </label>
            {info ? (
              <Popover className="relative h-5">
                <Popover.Button className="inline-flex items-center justify-center rounded-full p-0.5 text-white/50 transition hover:text-white focus:text-white focus:shadow-input-focus focus:outline-none">
                  <span className="sr-only">More information</span>
                  <InfoIcon />
                </Popover.Button>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Popover.Panel className="absolute left-0 w-48 rounded-xl border border-white/10 bg-surface-500 p-4 text-xs text-white/75">
                    {info}
                  </Popover.Panel>
                </Transition>
              </Popover>
            ) : null}
          </div>
        ) : null}
        <div
          className={`group px-4 ${small ? "rounded-[10px]" : "rounded-xl"} ${
            theme == "dark" ? "bg-surface-700 shadow-input" : "bg-[#1C1C21] shadow-input-light"
          }
        flex cursor-text items-center gap-3 transition-all ${
          disabled
            ? "bg-surface-800 shadow-input-disabled"
            : "focus-within:!shadow-input-focus hover:shadow-input-hover"
        }`}
          onClick={() => inputRef?.current?.focus()}
        >
          {icon ? <div className="pointer-events-none">{icon}</div> : null}
          <input
            ref={inputRef}
            id={name}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            disabled={disabled}
            className={`w-full ${
              small ? "h-9" : "h-12"
            } border-none bg-transparent p-0 font-medium text-white placeholder-white/50 transition focus:border-none focus:placeholder-white/20 focus:outline-none focus:ring-0 disabled:text-white/50 sm:text-sm sm:leading-none`}
          />
          <Transition
            show={error ? true : false}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="pointer-events-none text-comp-yellow-600">
              <ExclamationIcon />
            </div>
          </Transition>
          <Transition
            show={disabled && !error && !rightIcon}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="pointer-events-none text-white/50">
              <LockIcon />
            </div>
          </Transition>
          {rightIcon && !error && (
            <div className="pointer-events-none inline-flex items-center">{rightIcon}</div>
          )}
        </div>
        <Transition
          show={error ? true : false}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="text-xs text-comp-yellow-600">{error}</div>
        </Transition>
      </div>
    );
  }
);

const InfoIcon = () => (
  <svg
    className="h-4 w-4 shrink-0"
    aria-hidden="true"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 6.5a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75ZM7.288 7.809c-.038.092-.038.208-.038.441v2c0 .233 0 .35.038.441a.5.5 0 0 0 .27.27C7.652 11 7.768 11 8 11s.35 0 .441-.038a.5.5 0 0 0 .27-.27c.039-.092.039-.209.039-.442v-2c0-.233 0-.35-.038-.441a.5.5 0 0 0-.27-.27C8.348 7.5 8.232 7.5 8 7.5s-.35 0-.441.038a.5.5 0 0 0-.27.27Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm0-1.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z"
      fill="currentColor"
    />
  </svg>
);
const ExclamationIcon = () => (
  <svg
    className="h-4 w-4 shrink-0"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.822 8.28c-.025.254-.038.381-.096.478a.5.5 0 0 1-.215.194C8.41 9 8.281 9 8.026 9h-.052c-.256 0-.384 0-.485-.048a.5.5 0 0 1-.215-.194c-.058-.097-.07-.224-.096-.478l-.34-3.4c-.03-.305-.046-.458.004-.575a.5.5 0 0 1 .22-.244C7.175 4 7.328 4 7.634 4h.732c.306 0 .46 0 .572.061a.5.5 0 0 1 .22.244c.05.117.035.27.004.575l-.34 3.4ZM8 10.25A.875.875 0 1 0 8 12a.875.875 0 0 0 0-1.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-1.5 0a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
      fill="currentColor"
    />
  </svg>
);
const LockIcon = () => (
  <svg
    className="h-4 w-4 shrink-0"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1974_95)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5a3.75 3.75 0 0 0-3.75 3.75v1.762c-.245.015-.419.049-.567.124a1.25 1.25 0 0 0-.547.547C3 7.95 3 8.3 3 9v3.2c0 .98 0 1.47.19 1.845.169.329.436.597.766.764C4.33 15 4.82 15 5.8 15h4.4c.98 0 1.47 0 1.845-.19a1.75 1.75 0 0 0 .764-.766C13 13.67 13 13.18 13 12.2V9c0-.7 0-1.05-.136-1.317a1.25 1.25 0 0 0-.546-.547c-.149-.075-.323-.109-.568-.124V5.25A3.75 3.75 0 0 0 8 1.5ZM9.85 7c.14 0 .21 0 .264-.027a.25.25 0 0 0 .109-.11c.027-.053.027-.123.027-.263V5.25a2.25 2.25 0 0 0-4.5 0V6.6c0 .14 0 .21.027.264a.25.25 0 0 0 .11.109C5.94 7 6.01 7 6.15 7h3.7Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1974_95">
        <path fill="#fff" d="M3 1h10v14H3z" />
      </clipPath>
    </defs>
  </svg>
);

export default Input;
