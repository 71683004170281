import Image from "next/image";

import ButtonOrLink from "components/Button/ButtonOrLink";
import { useTranslation } from "next-i18next";
import CookieConsent from "react-cookie-consent";

const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <footer className="relative pb-24 pt-12 text-center text-white md:pb-0">
      <ButtonOrLink
        target="_blank"
        href="http://podspace.com"
        className="inline-flex items-center rounded p-1 focus:shadow-input-focus focus:outline-none focus:ring-0"
      >
        <Image
          className="h-6"
          aria-hidden="true"
          src="/assets/podspace-logo.svg"
          width="142"
          height="32"
          quality={100}
        />
        <span className="sr-only">Podspace</span>
      </ButtonOrLink>
      <nav className="mt-11 flex flex-wrap items-center justify-center gap-8 text-[13px] md:mt-14">
        <a
          href="/terms"
          target="_blank"
          className="rounded font-medium text-white/90 hover:text-white/90 focus:shadow-input-focus focus:outline-none focus:ring-0 md:text-white/70"
        >
          {t("terms_of_service")}
        </a>
        <a
          href="/privacy"
          target="_blank"
          className="rounded font-medium text-white/90 hover:text-white/90 focus:shadow-input-focus focus:outline-none focus:ring-0 md:text-white/70"
        >
          {t("privacy_policy")}
        </a>
        <a
          href="mailto:help@podspace.com"
          className="rounded font-medium text-white/90 hover:text-white/90 focus:shadow-input-focus focus:outline-none focus:ring-0 md:text-white/70"
        >
          {t("support")}
        </a>
      </nav>

      {/* Hide social medias  */}
      {/* <div className="mt-8 flex flex-wrap items-center justify-center gap-8">
        <a
          href="https://www.instagram.com/pod.space/"
          target="_blank"
          className="h-6 w-6 rounded transition hover:opacity-100 focus:shadow-input-focus focus:outline-none focus:ring-0 md:opacity-80"
          rel="noreferrer"
        >
          <Image
            className="h-6 w-6"
            aria-hidden="true"
            src="/assets/instagram.png"
            width="24"
            height="24"
            quality={100}
          />
        </a>
        <a
          href="https://www.facebook.com/hosted.by.podspace"
          target="_blank"
          className="h-6 w-6 rounded transition hover:opacity-100 focus:shadow-input-focus focus:outline-none focus:ring-0 md:opacity-80"
          rel="noreferrer"
        >
          <Image
            className="h-6 w-6"
            aria-hidden="true"
            src="/assets/facebook.png"
            width="24"
            height="24"
            quality={100}
          />
        </a>
        <a
          href="https://twitter.com/podspacesupport"
          target="_blank"
          className="h-6 w-6 rounded transition hover:opacity-100 focus:shadow-input-focus focus:outline-none focus:ring-0 md:opacity-80"
          rel="noreferrer"
        >
          <Image
            className="h-6 w-6"
            aria-hidden="true"
            src="/assets/twitter.png"
            width="24"
            height="24"
            quality={100}
          />
        </a>
      </div> */}
      <p className="mt-8 text-[13px] font-medium text-white/70">
        &copy; {new Date().getFullYear()} Podspace AB
      </p>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="cookie_consent"
        style={{ background: "#151a1c" }}
        buttonStyle={{
          background: "black",
          color: "white",
          padding: "8px 20px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. See{" "}
        <a target="_blank" className="inline underline" href="/privacy">
          our privacy policy
        </a>{" "}
        for details
      </CookieConsent>
    </footer>
  );
};

export default Footer;
