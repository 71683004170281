import { ComponentProps, useState } from "react";

import Toast from "../Toast/Toast";

type CopyToClipboardProps = ComponentProps<"button">;

interface Props extends CopyToClipboardProps {
  text: string | undefined;
}

export default function CopyToClipboardButton({ text, children, ...props }: Props) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (!text) {
      return;
    }

    if (!navigator.clipboard) {
      return alert(
        "Copying to clipboard only works on secure sites viewed through a modern browser."
      );
    }

    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
    });
  };
  return (
    <>
      <button {...props} onClick={copyToClipboard}>
        {children}
      </button>
      <Toast show={copied} onClose={() => setCopied(false)}>
        Copied to your clipboard
      </Toast>
    </>
  );
}
