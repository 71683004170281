import { useEffect, useState } from "react";

export default function GooglePodcasts({ ...props }) {
  const [step, setStep] = useState(1);

  useEffect(() => {
    const inerval = setInterval(() => {
      setStep(step >= 3 ? 1 : step + 1);
    }, 2000);

    return () => clearInterval(inerval);
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...props}
    >
      <path
        d="M21,12c0,4.9706-4.0294,9-9,9s-9-4.0294-9-9s4.02944-9,9-9s9,4.02944,9,9ZM13.9134,7.38061c-.9136-.37844-1.9189-.47746-2.8888-.28453s-1.86087.66913-2.56013,1.36839-1.17547,1.59013-1.36839,2.56013-.09391,1.9752.28452,2.8888s1.01931,1.6945,1.84155,2.2439C10.0444,16.7068,11.0111,17,12,17v2c-1.3845,0-2.73785-.4105-3.88899-1.1797s-2.04835-1.8624-2.57816-3.1415-.66844-2.6866-.39834-4.0444.93678-2.60518,1.91575-3.58415s2.22624-1.64565,3.58414-1.91574s2.7653-.13148,4.0444.39834s2.3723,1.42702,3.1415,2.57816s1.1797,2.50449,1.1797,3.88899h-2c0-.9889-.2932-1.9556-.8427-2.77785-.5494-.82224-1.3303-1.46311-2.2439-1.84154ZM12.574,10.6142c-.2741-.1135-.5757-.1433-.8666-.0854s-.5583.2008-.7681.4105c-.2097.2098-.3526.4771-.4105.7681s-.0281.5925.0854.8666.3058.5084.5524.6732.5367.2528.8334.2528v2c-.6922,0-1.3689-.2053-1.9445-.5899-.57557-.3845-1.02417-.9312-1.28908-1.5707s-.33422-1.3433-.19917-2.0222.46839-1.3026.95788-1.79207s1.11317-.82283,1.79207-.95788s1.3827-.06573,2.0222.19917s1.1862.71351,1.5707,1.28908c.3846.5756.5899,1.2523.5899,1.9445h-2c0-.2967-.088-.5867-.2528-.8334s-.3991-.4389-.6732-.5524Z"
        clipRule="evenodd"
        fill="#fdfdff"
        fillRule="evenodd"
        opacity={Number(step == 1)}
        style={{
          transform: `scale(${step == 1 ? 1 : 0})`,
          transformOrigin: "center",
          transition: "all 400ms cubic-bezier(.47,1.64,.41,.8)",
        }}
      />
      <path
        d="M12,21c4.9706,0,9-4.0294,9-9s-4.0294-9-9-9-9,4.02944-9,9s4.02944,9,9,9Zm.6964-14.80357C12.6964,5.8118,12.3846,5.5,12,5.5s-.6964.3118-.6964.69643v.92857c0,.38463.3118.69643.6964.69643s.6964-.3118.6964-.69643v-.92857Zm0,10.67857c0-.3846-.3118-.6964-.6964-.6964s-.6964.3118-.6964.6964v.9286c0,.3846.3118.6964.6964.6964s.6964-.3118.6964-.6964v-.9286Zm2.3215-8.70536c.3846,0,.6964.3118.6964.69643v.92857c0,.38466-.3118.69646-.6964.69646s-.6965-.3118-.6965-.69646v-.92857c0-.38463.3118-.69643.6965-.69643ZM9.67857,14.2054c0-.3847-.3118-.6965-.69643-.6965s-.69643.3118-.69643.6965v.9285c0,.3847.31181.6965.69643.6965s.69643-.3118.69643-.6965v-.9285Zm5.33933-2.7858c.3846,0,.6964.3118.6964.6965v3.0178c0,.3847-.3118.6965-.6964.6965s-.6965-.3118-.6965-.6965v-3.0178c0-.3847.3118-.6965.6965-.6965ZM9.67857,8.86607c0-.38463-.3118-.69643-.69643-.69643s-.69643.3118-.69643.69643v3.01783c0,.3847.31181.6965.69643.6965s.69643-.3118.69643-.6965v-3.01783ZM6.19643,10.8393c.38463,0,.69643.3118.69643.6964v.9286c0,.3846-.3118.6964-.69643.6964s-.69643-.3118-.69643-.6964v-.9286c0-.3846.3118-.6964.69643-.6964Zm10.91067.6964c0-.3846.3118-.6964.6965-.6964s.6964.3118.6964.6964v.9286c0,.3846-.3118.6964-.6964.6964s-.6965-.3118-.6965-.6964v-.9286ZM12,8.75c.3846,0,.6964.3118.6964.69643v5.10717c0,.3846-.3118.6964-.6964.6964s-.6964-.3118-.6964-.6964v-5.10717c0-.38463.3118-.69643.6964-.69643Z"
        transform="matrix(.5 0 0 0.5 6 6)"
        opacity={Number(step == 2)}
        style={{
          transform: `scale(${step == 2 ? 1 : 0})`,
          transformOrigin: "center",
          transition: "all 400ms cubic-bezier(.47,1.64,.41,.8)",
        }}
        clipRule="evenodd"
        fill="#fdfdff"
        fillRule="evenodd"
      />
      <path
        d="M21,12c0,4.9706-4.0294,9-9,9s-9-4.0294-9-9s4.02944-9,9-9s9,4.02944,9,9ZM14.3398,8.75574c-.6851-.49412-1.5091-.75866-2.3538-.75572s-1.6668.27324-2.34842.77214-1.18787,1.20078-1.4461,2.00504-.2552,1.6697.00863,2.4721.77494,1.5008,1.46006,1.995L8.19778,17.2719c-1.11332-.8029-1.94387-1.9377-2.3726-3.2417s-.43364-2.7103-.01402-4.0172s1.24222-2.44754,2.3499-3.25824s2.44364-1.24993,3.81624-1.25472s2.7116.42509,3.8249,1.22805s1.9439,1.93774,2.3726,3.24173.4337,2.71028.014,4.01718-1.2422,2.4475-2.3499,3.2582l-1.4765-2.0174c.6817-.4989,1.1879-1.2007,1.4461-2.005s.2552-1.6697-.0086-2.4721-.7749-1.50083-1.4601-1.99496ZM13.5,12c0,.8284-.6716,1.5-1.5,1.5s-1.5-.6716-1.5-1.5.6716-1.5,1.5-1.5s1.5.6716,1.5,1.5Z"
        transform="matrix(.5 0 0 0.5 6 6)"
        opacity={Number(step == 3)}
        style={{
          transform: `scale(${step == 3 ? 1 : 0})`,
          transformOrigin: "center",
          transition: "all 400ms cubic-bezier(.47,1.64,.41,.8)",
        }}
        clipRule="evenodd"
        fill="#fdfdff"
        fillRule="evenodd"
      />
    </svg>
  );
}
