import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import AnimateHeight from "react-animate-height";

import Button from "components/Button/Button";
import Dots from "public/assets/more-horizontal.svg";
import Lock from "public/assets/lock.svg";
import Explicit from "public/assets/explicit.svg";
import Listbox from "components/Listbox/Listbox";
import Switch from "components/Switch/Switch";
import dynamic from "next/dynamic";

import audioPlayingLottieIcon from "public/assets/audio-playing-lottie.json";
import { LottieRefCurrentProps } from "lottie-react";
import { formatRelativeDate, formatEpisodeDuration } from "utils/datetime";
import { AnimatePresence, motion } from "framer-motion";

export default function EpisodeList({
  episodes,
  seasons,
  activeEpisode,
  setActiveEpisode,
  activeSeason,
  setActiveSeason,
  autoplay,
  setAutoplay,
  loading,
  isPlaying,
  playbackSpeed,
}: {
  slug: string;
  episodes: any[];
  seasons: any[];
  activeEpisode: any;
  setActiveEpisode: Function;
  activeSeason: any;
  setActiveSeason: Function;
  autoplay: Boolean;
  setAutoplay: Function;
  loading: Boolean;
  isPlaying: Boolean;
  playbackSpeed: number;
}) {
  const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

  const { t, i18n } = useTranslation("common");
  const activeEpisodeRef = useRef<HTMLDivElement>(null);
  const [showAll, setShowAll] = useState(false);
  const audioPlayingLottieIconRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (episodes.findIndex((episode) => episode.id === activeEpisode?.id) > 0) {
      setShowAll(true);
      activeEpisodeRef.current?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }, [episodes]);

  useEffect(() => {
    audioPlayingLottieIconRef.current?.setSpeed(playbackSpeed);
    if (!isPlaying) {
      audioPlayingLottieIconRef.current?.playSegments([150, 175], true);
    }
  }, [playbackSpeed, episodes, activeEpisode, audioPlayingLottieIconRef.current, isPlaying]);

  return (
    <div>
      <AnimateHeight
        duration={500}
        height={showAll || episodes.length <= 8 ? "auto" : 620}
        className="relative -mx-1 overflow-y-clip overflow-x-visible px-1"
      >
        {loading ? (
          <div className="space-y-6 py-0.5" key="loading">
            <div className="h-10 w-full animate-shimmer rounded-md bg-gradient-to-r from-secondary-800 via-surface-600 to-secondary-800 bg-[length:400%_100%]"></div>
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={"episode-placeholder-" + index}
                className="flex flex-1 items-center justify-between gap-4 rounded-md text-left"
              >
                <div className="h-14 w-14 shrink-0 rounded-md bg-secondary-700"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 w-2/3 animate-shimmer rounded bg-gradient-to-r from-secondary-700 via-surface-600 to-secondary-700 bg-[length:400%_100%]"></div>
                  <div className="h-4 w-1/2 animate-shimmer rounded bg-gradient-to-r from-secondary-700 via-surface-600 to-secondary-700 bg-[length:400%_100%]"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <AnimatePresence>
            <motion.div
              className="space-y-6 py-0.5"
              key="episodes"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {activeSeason && seasons.length > 1 && (
                <Listbox selected={activeSeason} onChange={setActiveSeason} options={seasons} />
              )}
              {episodes.map((episode, index) => (
                <div key={episode.id}>
                  {activeEpisode?.id === episode.id && (
                    <div ref={activeEpisodeRef} className="mb-4 flex items-center gap-1">
                      <Image src="/assets/headphones.svg" width="24" height="24" />
                      <span className="animate-text-shimmer bg-[linear-gradient(90deg,#e2e8f0,45%,#1e293b,55%,#e2e8f0)] bg-[length:250%_100%] bg-clip-text text-[13px] font-semibold text-transparent">
                        {t("Playing now")}
                      </span>
                    </div>
                  )}
                  <div className="flex w-full items-center gap-4">
                    <button
                      tabIndex={index > 5 && !showAll ? -1 : 0}
                      className="group flex flex-1 items-center justify-between gap-4 rounded-md text-left focus:outline-none focus-visible:shadow-input-focus"
                      onClick={() => !episode.locked && setActiveEpisode(episode)}
                    >
                      <div className="flex items-center gap-4">
                        <div
                          className={`h-14 w-14 shrink-0 rounded-md ${
                            episode.image_url ? "" : "bg-white/50"
                          }`}
                        >
                          {episode.image_url && (
                            <div className="relative flex h-14 w-14 items-center justify-center">
                              <Image
                                layout="fill"
                                className={`${
                                  activeEpisode?.id === episode.id && "opacity-20"
                                } flex h-14 w-14 shrink-0  rounded-md transition`}
                                src={episode.image_url}
                                alt={episode.title}
                              />
                              {activeEpisode?.id === episode.id && (
                                <Lottie
                                  className="absolute h-6 w-6"
                                  animationData={audioPlayingLottieIcon}
                                  lottieRef={audioPlayingLottieIconRef}
                                  loop={!!isPlaying}
                                  initialSegment={[0, 150]}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="transition duration-500 group-hover:translate-x-1">
                          <div className="font-semibold text-white">
                            <span className="block whitespace-normal  ">{episode.title}</span>
                            {episode.locked && <Lock className="ml-2 inline-block fill-white" />}
                          </div>
                          <div className="flex flex-wrap items-center text-[13px] text-white/70">
                            {formatRelativeDate(episode.published_at, i18n.language)}
                            <span className="text-white/50 md:hidden lg:flex">
                              &nbsp;<span className="font-light text-white/70"> &bull; </span>&nbsp;
                              {t("Episode")}
                            </span>{" "}
                            {episode.explicit && (
                              <span className="inline-flex items-center">
                                &nbsp;<span className="font-light"> &bull; </span>&nbsp;
                                <Explicit className="inline-block fill-white/50 align-baseline" />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="hidden shrink-0 whitespace-nowrap text-[13px] font-medium text-white/50 lg:block">
                        {formatEpisodeDuration(episode.duration)}
                      </div>
                    </button>

                    {/* <button
                      tabIndex={index > 5 && !showAll ? -1 : 0}
                      className="shrink-0 rounded text-white/50 transition hover:text-white/70 focus:outline-none focus-visible:shadow-input-focus"
                    >
                      <Dots />
                    </button> */}
                  </div>
                  {activeEpisode?.id === episode.id && (
                    <div className="mt-4 flex items-center justify-between gap-2">
                      <div className="flex items-center gap-1">
                        <Image src="/assets/clock.svg" width="24" height="24" />
                        <span className="text-[13px] font-semibold text-white/50">
                          {t("Next up")}
                        </span>
                      </div>

                      <div
                        onClick={() => setAutoplay(!autoplay)}
                        className={`flex cursor-pointer items-center gap-1 ${
                          autoplay ? "!text-[#5CE7FF]" : "text-white/50"
                        }`}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.684 2.631c.331-.537 1.16-.263 1.108.365L8.5 6.5l2.74.365a.66.66 0 0 1 .475 1.001L8.316 13.37c-.331.536-1.16.263-1.108-.366L7.5 9.5l-2.74-.365a.66.66 0 0 1-.475-1.001L7.684 2.63Z"
                            fill="currentColor"
                          />
                        </svg>
                        <div className="flex items-center gap-2">
                          <span className="transition-color text-[13px] font-semibold">
                            {t("Autoplay")}
                          </span>
                          <Switch
                            label={t("Autoplay")}
                            checked={autoplay}
                            onChange={(value) => setAutoplay(value)}
                            small
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </motion.div>
          </AnimatePresence>
        )}
        {episodes.length > 8 && !showAll && (
          <div className="sticky bottom-0 flex flex-col">
            <div className="h-32 w-full bg-gradient-to-b from-surface-800/0 to-surface-800"></div>
            <div className="-mt-px flex justify-center bg-surface-800 pb-1 pt-6">
              <Button
                className="w-full lg:mx-auto lg:w-auto"
                size="small"
                importance="secondary"
                onClick={() => setShowAll(true)}
              >
                {t("more episodes")}
              </Button>
            </div>
          </div>
        )}
      </AnimateHeight>
    </div>
  );
}
