import React, { ReactNode, useEffect, useState, useMemo, useRef } from "react";
import Image from "next/image";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Transition } from "@headlessui/react";
import cookie from "cookie";
import { motion, useScroll, useTransform } from "framer-motion";
import axios from "axios";
import { compareDesc, parseISO } from "date-fns";

// API
import { fetchFromAPI, fetchSubscription } from "api/fetchFunctions";

// Components
import Bundle from "components/Bundle/Bundle";
import Player from "components/Player/Player";
import EpisodeList from "components/Episodes/EpisodeList";
import Card from "components/Card/Card";
import ButtonOrLink from "components/Button/ButtonOrLink";
import Disclosure, { DisclosureButton } from "components/Disclosure/Disclosure";
import PremiumBadge from "components/PremiumBadge/PremiumBadge";
import Row from "components/Row/Row";
import Footer from "components/Footer/Footer";
import LinkExternal from "public/assets/link-external.svg";
import Explicit from "public/assets/explicit.svg";
import ShowDetailsDialog from "components/ShowDetailsDialog";
import FAQ from "components/FAQ/FAQ";
import DetailRow from "components/DetailRow/DetailRow";
import ProductDetails from "components/ProductDetails";
import SubscriptionDetails from "components/SubscriptionDetails";

// Types
import type { NextPage } from "next";
import { Get_SubscriptionQuery, PageQuery, SubscriptionStatus } from "gql/graphql";
import SlideIn from "components/Transitions/SlideIn";
import Head from "next/head";
import Dialog from "components/Dialog/Dialog";
import Button from "components/Button/Button";
import SignupDialog from "components/Auth/SignupDialog";
import SigninDialog from "components/Auth/SigninDialog";
import ExpiredSubscriptionDialog from "components/Auth/ExpiredSubscriptionDialog";
import { getSanitizedHtml } from "utils/string";

type PremProps = {
  data: PageQuery;
  subscriptionData?: Get_SubscriptionQuery | null;
  episodes?: any[];
  seasons?: any[];
  hasSubscriptionError: boolean;
  hasLinkedError: boolean;
  token?: string;
  children?: ReactNode;
};

const Premium: NextPage<PremProps> = ({
  data,
  subscriptionData,
  hasSubscriptionError = false,
  hasLinkedError = false,
  token,
  children,
}) => {
  const router = useRouter();
  const planIndex = (router.query?.plan || 0) as number;

  const [isSubscribed, setIsSubscribed] = useState(
    subscriptionData != null && subscriptionData.subscription?.status != SubscriptionStatus.Expired
  );
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isDescriptionShrinked, setIsDescriptionShrinked] = useState(false);
  const [showSubscriptionError, setShowSubscriptionError] = useState(hasSubscriptionError);
  const [showLinkedError, setShowLinkedError] = useState(hasLinkedError);
  const [activeShow, setActiveShow]: [object | null, Function] = useState(null);
  const [showShowDetailsDialog, setShowShowDetailsDialog]: [boolean, Function] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<string>(
    router.query?.paymentOption || data?.page.product?.purchaseOptions[planIndex].identifier || ""
  );
  const [productName, setProductName] = useState<string>(data?.page.product?.name || "");
  const [trialDays, setTrialDays] = useState<number>(
    data?.page.product?.purchaseOptions[planIndex].trialDays || 0
  );

  const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(router.query?.signup == "1");
  const [isSigninDialogOpen, setIsSigninDialogOpen] = useState(
    router.query?.signin == "1" && !subscriptionData
  );
  const [isExpiredSubscriptionDialogOpen, setIsExpiredSubscriptionDialogOpen] = useState(
    subscriptionData?.subscription?.status == SubscriptionStatus.Expired
  );

  const [playbackSpeed, setPlaybackSpeed] = useState<1 | 1.5 | 2>(1);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);

  // useEffect(() => {
  //   if (isSignupDialogOpen) {
  //     router.push(`${slug}?signup=1&paymentOption=${selectedPaymentOption}`);
  //   } else {
  //     router.push(slug);
  //   }
  // }, [isSignupDialogOpen]);

  // useEffect(() => {
  //   if (isSigninDialogOpen) {
  //     router.push(`${slug}?signin=1`);
  //   } else {
  //     router.push(slug);
  //   }
  // }, [isSigninDialogOpen]);

  const displayShowDetailsDialog = (show: object) => {
    setActiveShow(show);
    setShowShowDetailsDialog(true);
  };

  const closeShowDetailsDialog = () => {
    setShowShowDetailsDialog(false);
    setTimeout(() => setActiveShow(null), 300);
  };

  const { t } = useTranslation("common");

  const slug = router.query.slug[0] as string;
  const episode = router.query.slug[1];

  const multipleShows = () => {
    return data?.page.product?.shows.length > 1 ?? false;
  };

  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 500], [0, 125]);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsInitialized(true));
  }, []);

  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current.scrollHeight !== descriptionRef.current.clientHeight) {
      setIsDescriptionShrinked(true);
    }
  }, [descriptionRef.current]);

  const [episodesLoading, setEpisodesLoading] = useState(true);

  const [episodes, setEpisodes] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [activeEpisode, setActiveEpisode] = useState();
  const [activeSeason, setActiveSeason] = useState();
  const [filteredEpisodes, setFilteredEpisodes] = useState([]);
  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    if (multipleShows()) return;
    if (data?.page.product) return;

    axios
      .get(`${process.env.NEXT_PUBLIC_REST_API_URL}/podcasts/${slug}`, {
        headers: {
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_REST_API_KEY}`,
        },
      })
      .then(({ data }) => {
        let tempEpisodes = data.episodes;

        setEpisodesLoading(false);

        if (!tempEpisodes) {
          return;
        }

        let seasons = tempEpisodes
          .reduce((carry, episode) => {
            if (carry.findIndex((c) => c.id === episode.season_number) < 0) {
              carry.push({
                id: episode.season_number,
                label:
                  episode.season_number !== null ? `Season ${episode.season_number}` : "No season",
              });
            }
            return carry;
          }, [])
          .sort((a, b) => a.id - b.id);

        setEpisodes(tempEpisodes);
        setSeasons(seasons);
        let tempActiveEpisode: (typeof tempEpisodes)[number];
        if (episode) {
          tempActiveEpisode = tempEpisodes?.filter((e) => e.url.split("/")[1] === episode)[0];
        } else {
          tempActiveEpisode = tempEpisodes
            ?.filter((e) => !e.locked)
            .sort((episodeLeft, episodeRight) =>
              compareDesc(parseISO(episodeLeft.published_at), parseISO(episodeRight.published_at))
            )[0];
        }

        setActiveEpisode(tempActiveEpisode);

        // Set active season to active episode's season
        setActiveSeason(seasons?.find((season) => season.id === tempActiveEpisode.season_number));
      });
  }, []);

  const updateFilteredEpisodes = () => {
    setFilteredEpisodes(episodes?.filter((episode) => episode.season_number === activeSeason?.id));
  };

  useEffect(() => updateFilteredEpisodes(), [activeSeason]);

  useEffect(() => {
    if (activeEpisode) {
      router.push(`${activeEpisode.url}`, undefined, { scroll: false });

      if (activeEpisode.season_number !== activeSeason?.id) {
        setActiveSeason(seasons.filter((s) => s.id === activeEpisode.season_number)[0]);
      }
    }
  }, [activeEpisode]);

  const previousEpisode = () => {
    const currentEpisodeIndex = filteredEpisodes.findIndex((e) => e.id === activeEpisode.id);
    let previous = filteredEpisodes[currentEpisodeIndex - 1];

    if (previous && !previous.locked) {
      setActiveEpisode(previous);
    } else {
      const currentSeasonIndex = seasons.findIndex((s) => s.id === activeSeason.id);
      const previousSeason = seasons[currentSeasonIndex - 1];

      if (previousSeason) {
        setActiveSeason(previousSeason);

        if (
          (previous = episodes.filter((e) => e.season_number === previousSeason.id && !e.locked)[0])
        ) {
          setActiveEpisode(previous);
        }
      }
    }
  };

  const nextEpisode = () => {
    const currentEpisodeIndex = filteredEpisodes.findIndex((e) => e.id === activeEpisode.id);
    let next = filteredEpisodes[currentEpisodeIndex + 1];

    if (next && !next.locked) {
      setActiveEpisode(next);
    } else {
      const currentSeasonIndex = seasons.findIndex((s) => s.id === activeSeason.id);
      const nextSeason = seasons[currentSeasonIndex + 1];

      if (nextSeason) {
        setActiveSeason(nextSeason);

        if ((next = episodes.filter((e) => e.season_number === nextSeason.id && !e.locked)[0])) {
          setActiveEpisode(next);
        }
      }
    }
  };

  const title = `${data.page.hero.title} | Podspace`;
  const category = data?.page.contentBlocks.find((block) => block.title === "Category").content;

  return (
    <>
      <Head>
        <title>{title}</title>
        {/* HTML Meta Tags */}
        <meta name="description" content={`Listen to ${data.page.hero.title} on Podspace`} />

        {/* Facebook Meta Tags */}
        <meta
          property="og:url"
          content={
            typeof window !== "undefined" &&
            (window.location.href || `https://premium.pod.space/${router.asPath}`)
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${data.page.hero.title} | Podspace`} />
        <meta property="og:description" content={`Listen to ${data.page.hero.title} on Podspace`} />
        <meta property="og:image" content={data?.page.hero.mediaUrl} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:domain"
          content={
            typeof window !== "undefined" && (window.location.host || "https://premium.pod.space")
          }
        />
        <meta
          property="twitter:url"
          content={
            typeof window !== "undefined" &&
            (window.location.href || `https://premium-dev.podspace.tech/${router.asPath}`)
          }
        />
        <meta name="twitter:title" content={`${data.page.hero.title} | Podspace`} />
        <meta
          name="twitter:description"
          content={`Listen to ${data.page.hero.title} on Podspace`}
        />
        <meta name="twitter:image" content={data?.page.hero.mediaUrl} />
        <meta name="twitter:site" content="@podspace_app" />

        <link
          rel="alternate"
          type="application/rss+xml"
          title={data.page.hero.title}
          href={`https://feed.pod.space/${slug}`}
        />
        <link rel="canonical" href={`https://play.pod.space/${slug}`} />
      </Head>
      {/* Real content */}
      <nav className="pointer-events-none fixed inset-x-0 z-10 mx-auto flex max-w-screen-2xl items-center justify-between px-8 py-5 lg:px-9 lg:py-6">
        <span className="sr-only">Navigation</span>

        <div className="pointer-events-auto flex items-center gap-3">
          {/* <button className="w-8 h-8 flex items-center justify-center bg-surface-500 rounded-lg transition md:bg-transparent hover:bg-surface-700 focus:outline-none focus:ring-0 focus:shadow-input-focus">
            <Menu className="inline-block fill-white" />
          </button> */}
        </div>

        {/* <div className="flex items-center gap-4 pointer-events-auto">
          <button className="w-8 h-8 hidden rounded md:flex md:items-center md:justify-center focus:outline-none focus:ring-0 focus:shadow-input-focus">
            <Share className="inline-block fill-white/50" />
          </button>
          <button className="w-8 h-8 hidden rounded md:flex md:items-center md:justify-center focus:outline-none focus:ring-0 focus:shadow-input-focus">
            <More className="inline-block fill-white/50" />
          </button>
        </div> */}
      </nav>
      <main className="min-h-screen overflow-hidden md:max-h-screen">
        {/* Desktop/tablet album art */}
        {activeEpisode ? (
          <aside className="relative md:pointer-events-none md:fixed md:inset-y-0 md:left-0 md:flex md:h-screen md:w-1/2 md:items-center md:justify-center md:border-r md:border-white/[0.03] md:bg-[#0E0E10]  md:text-white md:after:absolute md:after:-right-1 md:after:h-full md:after:w-[1px] md:after:border md:after:border-black/[0.32]">
            <Player
              activeEpisode={activeEpisode}
              setActiveEpisode={setActiveEpisode}
              previousEpisode={previousEpisode}
              nextEpisode={nextEpisode}
              autoplay={autoplay}
              playbackSpeed={playbackSpeed}
              setPlaybackSpeed={(speed: number) => setPlaybackSpeed(speed as 1 | 1.5 | 2)}
              handleIsPlaying={(isPlaying: boolean) => setIsPlayingAudio(isPlaying)}
            />
          </aside>
        ) : (
          <aside className="hidden h-screen bg-[#09090C] text-white md:pointer-events-none md:fixed md:inset-y-0 md:left-0 md:flex md:w-1/2 md:items-center md:justify-center md:border-r md:border-white/[0.03] md:after:absolute md:after:-right-1 md:after:h-full md:after:w-[1px] md:after:border md:after:border-black/[0.32]">
            <div className="ml-auto flex w-full max-w-[800px] justify-center px-6 lg:px-0">
              <div className="w-full lg:w-[480px]">
                <div className="aspect-square w-full overflow-hidden md:rounded-3xl">
                  <Image
                    src={data?.page.hero.mediaUrl}
                    alt={data?.page.hero.title}
                    width={800}
                    height={800}
                    layout="responsive"
                    objectFit="contain"
                    priority
                    quality={100}
                  />
                </div>
              </div>
            </div>
          </aside>
        )}
        <div
          id="content"
          className={`overflow-y-auto overflow-x-hidden  text-white md:h-screen md:pl-[50%] md:pb-20`}
        >
          <div className="mr-auto flex w-full max-w-[800px] justify-center">
            <div className="w-full md:max-w-[33rem] md:px-6">
              <header className="relative z-[1] aspect-square overflow-hidden px-4 text-center md:z-auto md:aspect-auto md:pt-[144px] md:text-left">
                <ButtonOrLink
                  href="http://podspace.com"
                  target="_blank"
                  className="absolute top-12 hidden rounded p-1 focus:shadow-input-focus focus:outline-none focus:ring-0 md:inline-flex md:items-center"
                >
                  <img src="/assets/podspace-logo.svg" className="h-6 md:h-8" aria-hidden="true" />
                  <span className="sr-only">Podspace</span>
                </ButtonOrLink>
                {/* Mobile album art */}
                <div
                  className={`absolute top-0 -z-10 -mx-4 aspect-square w-full overflow-hidden transition duration-1000 ease-in-out md:hidden ${
                    !isInitialized ? "scale-110 opacity-0" : "scale-100"
                  }`}
                >
                  <motion.div style={{ y }} className="h-full w-full">
                    <div
                      style={{ backgroundImage: `url(${data?.page.hero.mediaUrl})` }}
                      className="absolute inset-0 bottom-0 h-full w-full bg-cover bg-top"
                    >
                      {/* Album blur */}
                      <div
                        className="absolute inset-0 h-full w-full bg-cover bg-top blur"
                        style={{
                          backgroundImage: "inherit",
                          WebkitMaskImage: "linear-gradient(to bottom, transparent 50%, black)",
                          maskImage: "linear-gradient(to bottom, transparent 50%, black)",
                        }}
                      ></div>
                    </div>
                  </motion.div>
                </div>
                {/* Album gradient */}
                <div className="absolute top-0 bottom-0 -z-10 -mx-4 aspect-square w-full overflow-hidden md:hidden">
                  <div className="absolute  inset-x-0 bottom-0 h-full w-full overflow-hidden md:hidden">
                    <div className="absolute inset-x-0 bottom-0 h-1/2 w-full bg-gradient-to-b from-[#000]/0 to-[#000]"></div>
                  </div>
                </div>
                <div className="absolute inset-x-0  bottom-0 z-[1] px-4 md:relative md:px-0">
                  <div className="relative">
                    <figure>
                      {data?.page.hero.iconUrl && (
                        <span className="mx-auto mb-2 block w-8 md:ml-0 md:w-12 lg:w-16">
                          <Image
                            src={data?.page.hero.iconUrl}
                            alt={data?.page.hero.title}
                            width={64}
                            height={64}
                            priority
                            className="rounded-full"
                            quality={100}
                          />
                        </span>
                      )}
                      <figcaption className="text-[10px] font-bold uppercase leading-none tracking-wider text-white/50 md:text-xs md:font-semibold">
                        {data?.page.hero.author}
                      </figcaption>
                    </figure>
                    <h1 className="hyphens-auto mt-3 break-words font-display text-[32px] font-bold leading-none md:text-[40px] lg:text-5xl">
                      {data?.page.hero.title}
                    </h1>
                    <div className="flex items-center justify-center gap-3 overflow-hidden pb-1 pt-3 md:justify-start">
                      {data?.page.product && <PremiumBadge />}
                      <p className="truncate  whitespace-nowrap text-[13px] font-medium text-white/70 md:text-sm">
                        {category}
                        {"・"}
                        {data?.page.hero.metadata.join("・")}
                        {data?.page.hero.explicit && (
                          <>
                            {"・"}
                            <Explicit className="inline-block fill-white/70 pt-[1px] align-baseline" />
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </header>
              <section className="relative z-10 mx-auto -mt-1 w-full max-w-xl bg-almostBlack px-4 pt-7 text-white/90 md:max-w-none lg:text-xl">
                <p
                  ref={descriptionRef}
                  className={
                    showFullDescription ? "line-clamp-none" : "line-clamp-3 md:line-clamp-4"
                  }
                  dangerouslySetInnerHTML={{
                    __html: isInitialized
                      ? getSanitizedHtml(data?.page.contentBlocks[0].content, ["a", "p", "br"])
                      : "",
                  }}
                ></p>

                {isDescriptionShrinked && !showFullDescription && (
                  <button
                    onClick={() => setShowFullDescription(!showFullDescription)}
                    className="mt-2 inline-flex items-center rounded text-sm font-semibold text-white/50 transition hover:text-white/70 focus:shadow-input-focus focus:outline-none focus:ring-0 md:mt-4"
                  >
                    {t("show_more")}
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m8 10.5 4 4 4-4"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </section>
              {data?.page.product && (
                <div className="relative mx-auto mt-6 w-full max-w-xl px-2 md:mt-8 md:max-w-none md:px-0 lg:mt-10">
                  <Card title={t("premium pass")} importance="premium">
                    {isSubscribed ? (
                      <SubscriptionDetails
                        subscriptionData={subscriptionData}
                        data={data}
                        multipleShows={multipleShows()}
                        displayShowDetailsDialog={displayShowDetailsDialog}
                      />
                    ) : (
                      <ProductDetails
                        data={data}
                        productName={productName}
                        trialDays={trialDays}
                        selectedPaymentOption={selectedPaymentOption}
                        setSelectedPaymentOption={setSelectedPaymentOption}
                        setIsSignupDialogOpen={setIsSignupDialogOpen}
                        setIsSigninDialogOpen={setIsSigninDialogOpen}
                      />
                    )}
                  </Card>
                </div>
              )}
              {data?.page.product && multipleShows() && (
                <div className="mx-auto mt-12 w-full max-w-xl px-2 md:mt-16 md:max-w-none">
                  <Bundle productName={productName} shows={data.page.product.shows} />
                </div>
              )}
              {episodes.length > 0 && (
                <SlideIn>
                  <section className="mx-auto mt-12 w-full max-w-xl px-2 md:mt-16 md:max-w-none">
                    {data?.page.product ? (
                      <Disclosure defaultOpen={true}>
                        <DisclosureButton>{t("episodes_header")}</DisclosureButton>
                        <Transition
                          enter="origin-top-left transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="origin-top-left transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel className="px-2 pt-6 pb-8">
                            <EpisodeList
                              slug={slug}
                              episodes={filteredEpisodes}
                              seasons={seasons}
                              activeEpisode={activeEpisode}
                              setActiveEpisode={setActiveEpisode}
                              activeSeason={activeSeason}
                              setActiveSeason={setActiveSeason}
                              autoplay={autoplay}
                              setAutoplay={setAutoplay}
                              loading={episodesLoading}
                              isPlaying={isPlayingAudio}
                              playbackSpeed={playbackSpeed}
                            />
                          </Disclosure.Panel>
                        </Transition>
                      </Disclosure>
                    ) : (
                      <div className="px-2 pb-8">
                        <EpisodeList
                          slug={slug}
                          episodes={filteredEpisodes}
                          seasons={seasons}
                          activeEpisode={activeEpisode}
                          setActiveEpisode={setActiveEpisode}
                          activeSeason={activeSeason}
                          setActiveSeason={setActiveSeason}
                          autoplay={autoplay}
                          setAutoplay={setAutoplay}
                          loading={episodesLoading}
                          isPlaying={isPlayingAudio}
                          playbackSpeed={playbackSpeed}
                        />
                      </div>
                    )}
                  </section>
                </SlideIn>
              )}
              <SlideIn>
                <section
                  className={`mx-auto w-full max-w-xl px-2 md:max-w-none ${
                    multipleShows() ? "mt-4" : "mt-6 md:mt-16"
                  }`}
                >
                  <Disclosure defaultOpen={true}>
                    <DisclosureButton>{t("details_header")}</DisclosureButton>
                    <Transition
                      enter="origin-top-left transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="origin-top-left transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="px-2 pb-8">
                        <dl className="divide-y divide-white/10">
                          {data?.page.contentBlocks.slice(1).map((block, index) => (
                            <SlideIn key={index}>
                              {block.type === "link" ? (
                                <Row className="justify-between py-3">
                                  <ButtonOrLink
                                    href={block.data!}
                                    className="group flex w-full items-center justify-between gap-4 rounded focus:shadow-input-focus focus:outline-none focus:ring-0"
                                  >
                                    <div className="flex flex-col-reverse !items-start">
                                      <dt className="text-[13px] leading-[24px] text-white/70">
                                        {t(block.title ?? "")}
                                      </dt>
                                      <dd className="font-semibold leading-[24px] text-white">
                                        {block.content}
                                      </dd>
                                    </div>
                                    <LinkExternal className="h-6 w-6 fill-white/50 transition group-hover:fill-white/70" />
                                  </ButtonOrLink>
                                </Row>
                              ) : (
                                <DetailRow title={t(block.title ?? "")} value={block.content} />
                              )}
                            </SlideIn>
                          ))}
                        </dl>
                      </Disclosure.Panel>
                    </Transition>
                  </Disclosure>
                </section>
              </SlideIn>
              {slug.includes("thirdear") && data?.page.product && (
                <SlideIn>
                  <section
                    id="faq"
                    className="mx-auto mt-4 w-full max-w-xl scroll-m-8 px-2 md:max-w-none"
                  >
                    <Disclosure defaultOpen={true}>
                      <DisclosureButton>{t("faq_header")}</DisclosureButton>
                      <Transition
                        enter="origin-top-left transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="origin-top-left transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="divide-y divide-white/10 px-2">
                          <SlideIn>
                            <FAQ question={t("faq_question_1", { title: productName })}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("faq_answer_1", { title: productName, trialDays }),
                                }}
                              />
                            </FAQ>
                          </SlideIn>
                          <SlideIn>
                            <FAQ question={t("faq_question_2")}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("faq_answer_2", {
                                    priceMonth:
                                      data?.page.product?.purchaseOptions[0].price.displayPrice,
                                    priceYear:
                                      data?.page.product?.purchaseOptions[1].price.displayPrice,
                                  }),
                                }}
                              />
                            </FAQ>
                          </SlideIn>
                          <SlideIn>
                            <FAQ question={t("faq_question_3")}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("faq_answer_3"),
                                }}
                              />
                            </FAQ>
                          </SlideIn>
                          <SlideIn>
                            <FAQ question={t("faq_question_4")}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("faq_answer_4"),
                                }}
                              />
                            </FAQ>
                          </SlideIn>
                          <SlideIn>
                            <FAQ question={t("faq_question_5")}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("faq_answer_5"),
                                }}
                              />
                            </FAQ>
                          </SlideIn>
                        </Disclosure.Panel>
                      </Transition>
                    </Disclosure>
                  </section>
                </SlideIn>
              )}
              <Footer />
            </div>
          </div>
        </div>
        <Dialog
          show={showSubscriptionError}
          title={t("error_no_subscription_title")}
          image="/assets/modal-power.png"
          onClose={() => setShowSubscriptionError(false)}
          small
        >
          <p className="mt-4 text-center text-white/70">
            <Trans i18nKey="error_no_subscription_body">
              No <span className="text-white/90">{productName}</span> subscription.
            </Trans>
          </p>
          <Button
            importance="primary"
            className="mt-8 w-full"
            onClick={() => setShowSubscriptionError(false)}
          >
            {t("error_no_subscription_button")}
          </Button>
          <p className="mt-6 text-center text-xs text-white/70">
            <Trans i18nKey="contact_support_team">
              Please contact our{" "}
              <ButtonOrLink
                href="mailto:help@podspace.com"
                className="rounded text-white/90 underline focus:shadow-input-focus focus:outline-none focus:ring-0"
              >
                Support Team
              </ButtonOrLink>{" "}
              for any questions.
            </Trans>
          </p>
        </Dialog>

        <Dialog
          show={showLinkedError}
          title="Already linked"
          image="/assets/modal-power.png"
          onClose={() => setShowLinkedError(false)}
          small
        >
          <p className="mt-4 text-center text-white/70">
            It seems like your account is already linked to another Spotify account. You can visit{" "}
            <a href="https://content-access.spotify.com" className="text-white/90 underline">
              Spotify
            </a>{" "}
            from the previously linked account to unlink it.
          </p>
          <Button
            importance="primary"
            className="mt-8 w-full"
            onClick={() => setShowLinkedError(false)}
          >
            {t("error_no_subscription_button")}
          </Button>
        </Dialog>

        <ShowDetailsDialog
          show={activeShow}
          productName={productName}
          open={showShowDetailsDialog}
          onClose={closeShowDetailsDialog}
        />
        {isSignupDialogOpen && (
          <SignupDialog
            selectedPaymentOption={selectedPaymentOption}
            productName={productName}
            isOpen={isSignupDialogOpen}
            setIsOpen={setIsSignupDialogOpen}
            trialDays={trialDays}
          />
        )}
        {isSigninDialogOpen && (
          <SigninDialog
            showSubscriptionError={() => {
              setIsSigninDialogOpen(false);
              setShowSubscriptionError(true);
            }}
            selectedPaymentOption={selectedPaymentOption}
            productName={productName}
            isOpen={isSigninDialogOpen}
            setIsOpen={setIsSigninDialogOpen}
          />
        )}
        {data?.page.product && (
          <ExpiredSubscriptionDialog
            selectedPaymentOption={selectedPaymentOption}
            productName={productName}
            purchaseOptions={data?.page.product?.purchaseOptions}
            trialDays={trialDays}
            isOpen={isExpiredSubscriptionDialogOpen}
            setIsOpen={setIsExpiredSubscriptionDialogOpen}
            token={token}
          />
        )}
      </main>
    </>
  );
};

export default Premium;

export const getServerSideProps: GetServerSideProps<PremProps> = async (
  context: GetServerSidePropsContext
) => {
  const params = context.params;

  if (context.query.token) {
    context.res.setHeader(
      "Set-Cookie",
      cookie.serialize("token", context.query.token as string, {
        httpOnly: true,
        secure: process.env.NODE_ENV !== "development",
        maxAge: 60 * 60 * 24 * 30,
        path: "/",
      })
    );
  }

  // try {
  const data = await fetchFromAPI(params?.slug[0] as string, context.locale);
  if (!data.page) {
    return {
      notFound: true,
    };
  }
  const linkedError = context.query.linkerror !== undefined;
  let props: PremProps = { data, hasLinkedError: linkedError };
  const token = (context.query.token as string) || context.req.cookies.token;
  if (token) {
    const subscriptionData = await fetchSubscription(params?.slug[0] as string, token as string);
    if (subscriptionData.subscription) {
      props = { ...props, subscriptionData, token };
    } else if (context.query.token) {
      props = { ...props, token, hasSubscriptionError: true };
    }
  }

  return {
    props: {
      ...props,
      ...(await serverSideTranslations(context.locale ?? "en", ["common"])),
    },
  };
};
