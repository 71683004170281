import { useState, useEffect, Fragment, useContext } from "react";
import { useRouter } from "next/router";

import { Transition } from "@headlessui/react";

import { useTranslation, Trans } from "next-i18next";
import { emailSchema } from "utils/validation";

import Button from "components/Button/Button";
import ButtonOrLink from "components/Button/ButtonOrLink";
import Dialog from "components/Dialog/Dialog";
import Input from "components/Input/Input";

import Apple from "public/assets/apple.svg";
import Google from "public/assets/google.svg";

function stripeUrl(authToken: string, purchaseOptionsId: string): string {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/premium/purchase?token=${authToken}&id=${purchaseOptionsId}`;
}

export default function Signup({
  isOpen,
  setIsOpen,
  selectedPaymentOption,
  productName,
  trialDays,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedPaymentOption: string;
  productName: string;
  trialDays: number;
}) {
  const router = useRouter();
  const { t } = useTranslation("common");
  const { result, payment_option_id, token, code } = router.query;
  const slug = (router.query.slug?.[0] as string) || "";
  let [emailError, setEmailError] = useState("");
  let [step, setStep] = useState<1 | 2 | 3>(1);
  let [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  let [confirmEmail, setConfirmEmail] = useState<boolean>(false);

  let [email, setEmail] = useState((router.query.email as string) || "");

  const createAccount = async () => {
    if (isRedirecting) return;

    let validatedEmail;
    try {
      validatedEmail = emailSchema.parse(email.trim());
    } catch (err) {
      return setEmailError(t("errors_invalid_email"));
    }

    if (router.query.result == "confirm") {
      router.push(stripeUrl(router.query?.auth, selectedPaymentOption));
      return;
    }

    setEmailError("");
    setIsRedirecting(true);
    setConfirmEmail(true);

    const data = await fetch("/api/auth/signup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: validatedEmail,
        paymentOptionId: selectedPaymentOption,
      }),
    }).then((res) => res.json());

    if (data) {
      if (data?.userCreate.result === "verification_sent") {
        setIsRedirecting(false);
        setConfirmEmail(false);
        return setStep(2);
      }
      router.push(stripeUrl(data?.userCreate.session.authToken, selectedPaymentOption));
    }
  };

  useEffect(() => {
    if (router.query?.email) {
      if (router.query.result == "confirm") {
        setConfirmEmail(true);
      } else {
        setStep(2);
      }
    }
    if (result === "failed") {
      setStep(3);
    }
  }, [router.query?.email, router.query?.result, result]);

  let [verificationCode, setVerificationCode] = useState(code || "");
  let [verificationCodeError, setVerificationCodeError] = useState("");

  const verify = async () => {
    if (!verificationCode) {
      return setVerificationCodeError(t("errors_invalid_verification"));
    } else {
      setEmailError("");
    }
    setIsRedirecting(true);
    setConfirmEmail(true);

    const data = await fetch("/api/auth/verify", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: verificationCode.trim(),
        slug: slug,
      }),
    }).then((res) => res.json());

    if (!data.userVerify) {
      setIsRedirecting(false);
      return setVerificationCodeError(t("errors_invalid_verification"));
    } else {
      setIsRedirecting(false);
      setConfirmEmail(false);
      router.push(
        stripeUrl(data?.userVerify.authToken, selectedPaymentOption || payment_option_id)
      );
    }
  };

  const Dialogue_Metas = {
    "1": {
      title: t("signup_title"),
      description: (
        <span>
          {t("signup_description", { trialDays: trialDays })}
          <strong className="font-normal text-white/90">{productName}</strong>.
        </span>
      ),
      image: "/assets/modal-hello.png",
    },
    "2": {
      title: t("signup_verification_title"),
      description: t("signup_verification_description"),
      image: "/assets/modal-inbox.png",
    },
    "3": {
      title: t("payment_issues_title"),
      description: t("payment_issues_description"),
      image: "/assets/modal-power.png",
    },
  };

  return (
    <div>
      <Dialog
        show={isOpen}
        title={Dialogue_Metas[step].title}
        description={Dialogue_Metas[step].description}
        image={Dialogue_Metas[step].image}
        onClose={() => setIsOpen(false)}
      >
        <Transition
          show={step == 1}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
        >
          <div>
            {/* Only show disabled email field and button when email has been submitted */}
            <Transition show={!confirmEmail} as={Fragment}>
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Button
                  fullWidth
                  className="mt-6 flex items-center gap-2"
                  importance="secondary"
                  href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/premium/register/apple?payment_option_id=${selectedPaymentOption}&src=signup`}
                  // onClick={fakeAppleAndGoogleSignin}
                >
                  <Apple className="w-6" /> {t("signup_apple")}
                </Button>
                <Button
                  fullWidth
                  className="mt-4 flex items-center gap-2"
                  importance="secondary"
                  href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/premium/register/google?payment_option_id=${selectedPaymentOption}&src=signup`}
                >
                  <Google className="w-6" /> {t("signup_google")}
                </Button>
                <div className="mt-4 flex items-center gap-4 px-6 text-sm font-semibold leading-6 text-white/70">
                  <span className="h-px w-full flex-1 bg-white/[.08]"></span>
                  <span>{t("or")}</span>
                  <span className="h-px w-full flex-1 bg-white/[.08]"></span>
                </div>
              </Transition.Child>
            </Transition>
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                createAccount();
              }}
            >
              <div className="mt-4">
                <Input
                  name="email"
                  icon={<EmailIcon />}
                  placeholder={t("input_placeholder_email")}
                  value={email}
                  error={emailError}
                  onChange={(e: any) => setEmail(e.target.value)}
                  disabled={confirmEmail}
                  inputmode="email"
                />
              </div>
              <Button fullWidth className="mt-4" importance="primary" type="submit">
                {isRedirecting ? (
                  <img className="h-4 w-4 animate-spin-fast" src="/assets/spinner.png" />
                ) : (
                  <span>{confirmEmail ? t("start_subscription") : t("signup_button_create")}</span>
                )}
              </Button>
            </form>
            <p className="mt-6 text-center text-xs text-white/70">
              {t("signup_terms_description")}
              <br />{" "}
              <a
                href="/privacy"
                target="_blank"
                className="rounded text-white underline focus-visible:shadow-input-focus focus-visible:outline-none"
              >
                {t("privacy_policy")}
              </a>{" "}
              {t("and")}{" "}
              <a
                href="/terms"
                target="_blank"
                className="rounded text-white underline focus-visible:shadow-input-focus focus-visible:outline-none"
              >
                {t("terms_of_service")}
              </a>
            </p>
          </div>
        </Transition>
        <Transition
          show={step == 2}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
        >
          <div>
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                verify();
              }}
            >
              <div className="mt-4">
                <Input
                  name="email"
                  icon={<EmailSentIcon />}
                  placeholder="Enter your email"
                  value={email}
                  error={emailError}
                  onChange={(e: any) => setEmail(e.target.value)}
                  disabled
                  inputmode="email"
                />
              </div>
              <div className="mt-4">
                <Input
                  name="verification-code"
                  icon={<VerificationIcon />}
                  label="Verification code"
                  placeholder="Paste verification code"
                  info={
                    <span>
                      {t("no_code_message")}{" "}
                      <a
                        href="#"
                        className="rounded text-white/90 underline focus:shadow-input-focus focus:outline-none"
                      >
                        {t("resend_code")}
                      </a>
                    </span>
                  }
                  value={verificationCode}
                  error={verificationCodeError}
                  onChange={(e: any) => setVerificationCode(e.target.value)}
                  onKeyDown={(e: any) => e.key == "Enter" && (e.preventDefault() || verify())}
                  disabled={isRedirecting}
                />
                <p className="mt-3 text-left text-xs text-white/70">
                  {t("verification_sent", { email })}
                  <br />
                  <button
                    onClick={(e: any) => e.preventDefault() || setStep(1)}
                    className="rounded text-white/90 underline focus:shadow-input-focus focus:outline-none"
                  >
                    {t("wrong_email_message")}
                  </button>
                </p>
              </div>
              <Button fullWidth className="mt-4" importance="primary" type="submit">
                {isRedirecting ? (
                  <img className="h-4 w-4 animate-spin-fast" src="/assets/spinner.png" />
                ) : (
                  <span>{t("signup_button_verify")}</span>
                )}
              </Button>
            </form>
          </div>
        </Transition>
        <Transition
          show={step == 3}
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
        >
          <div>
            <Button
              onClick={() => {
                router.push(stripeUrl(token, payment_option_id));
              }}
              fullWidth
              importance="primary"
              className="mt-8"
            >
              {t("Try again")}
            </Button>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              importance="secondary"
              className="mt-4"
            >
              {t("Cancel")}
            </Button>
            <p className="mt-6 text-center text-xs text-white/70">
              <Trans i18nKey="contact_support_team">
                Please contact our{" "}
                <ButtonOrLink
                  href="mailto:help@podspace.com"
                  className="rounded text-white/90 underline focus:shadow-input-focus focus:outline-none focus:ring-0"
                >
                  Support Team
                </ButtonOrLink>{" "}
                for any questions.
              </Trans>
            </p>
          </div>
        </Transition>
      </Dialog>
    </div>
  );
}

const EmailIcon = () => (
  <svg
    className="w-4 shrink-0 text-white/50 transition group-focus-within:text-white"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.879 0H3.12c-.395 0-.736 0-1.017.023-.297.024-.592.078-.875.222a2.25 2.25 0 0 0-.984.984c-.144.283-.198.578-.222.875C0 2.384 0 2.726 0 3.12v7.758c0 .395 0 .736.023 1.017.024.297.078.592.222.875.216.424.56.768.984.984.283.144.578.198.875.222.28.023.622.023 1.017.023h9.758c.395 0 .736 0 1.017-.023.297-.024.592-.078.875-.222a2.25 2.25 0 0 0 .984-.984c.144-.283.198-.578.222-.875.023-.28.023-.622.023-1.017V3.12c0-.395 0-.736-.023-1.017-.024-.297-.078-.592-.222-.875a2.25 2.25 0 0 0-.984-.984c-.283-.144-.578-.198-.875-.222C13.616 0 13.274 0 12.88 0ZM2.205 1.52l.02-.002c.213-.017.493-.018.925-.018h9.7a12.753 12.753 0 0 1 .945.02l-1.442 1.184c-1.366 1.123-2.732 2.246-4.1 3.367-.09.074-.135.111-.185.126a.25.25 0 0 1-.136 0c-.05-.015-.095-.052-.186-.126-1.367-1.121-2.733-2.244-4.1-3.367L2.207 1.52ZM1.5 2.882v7.968c0 .432 0 .712.018.924.017.204.045.28.064.317a.75.75 0 0 0 .328.327c.037.02.112.047.316.064.212.017.492.018.924.018h9.7c.432 0 .712 0 .924-.018.204-.017.28-.045.317-.064a.75.75 0 0 0 .327-.327c.02-.038.047-.113.064-.317.017-.212.018-.492.018-.924V2.882L8.97 7.424c-.078.064-.165.136-.247.193-.093.066-.22.143-.383.189a1.25 1.25 0 0 1-.68 0 1.253 1.253 0 0 1-.383-.19c-.082-.056-.169-.128-.247-.192L1.5 2.882Z"
      fill="currentColor"
    />
  </svg>
);
const EmailSentIcon = () => (
  <svg
    className="w-4 shrink-0 text-white/50 transition group-focus-within:text-white"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.121 0h9.758c.395 0 .736 0 1.017.023.297.024.592.078.875.222.424.216.768.56.984.984.144.283.198.578.222.875.023.28.023.622.023 1.017V6.25c0 .233 0 .35-.038.441a.5.5 0 0 1-.27.27C15.6 7 15.482 7 15.25 7s-.35 0-.441-.038a.5.5 0 0 1-.27-.27c-.039-.093-.039-.209-.039-.442V2.882L8.97 7.424a4.397 4.397 0 0 1-.247.193c-.093.066-.22.143-.383.189a1.25 1.25 0 0 1-.68 0 1.253 1.253 0 0 1-.383-.19c-.082-.056-.169-.128-.247-.192L1.5 2.882v7.968c0 .432 0 .712.018.924.017.204.045.28.064.317a.75.75 0 0 0 .328.327c.037.02.112.047.316.064.212.017.492.018.924.018h10.896c.152 0 .228 0 .263-.03a.125.125 0 0 0 .043-.105c-.003-.046-.057-.1-.164-.206L12.81 10.78c-.165-.164-.247-.247-.286-.339a.5.5 0 0 1 0-.382c.039-.092.12-.175.286-.34.164-.164.247-.246.339-.284a.5.5 0 0 1 .382 0c.092.038.175.12.34.285l2.964 2.964c.198.198.297.297.334.412a.5.5 0 0 1 0 .309c-.037.114-.136.213-.334.41L13.87 16.78c-.164.165-.247.247-.339.285a.5.5 0 0 1-.382 0c-.092-.038-.175-.12-.34-.285-.164-.164-.246-.247-.284-.339a.5.5 0 0 1 0-.382c.038-.092.12-.175.285-.34l1.378-1.378c.107-.107.16-.16.164-.206a.125.125 0 0 0-.043-.105c-.035-.03-.111-.03-.262-.03H3.12c-.395 0-.736 0-1.017-.023-.297-.024-.592-.078-.875-.222a2.25 2.25 0 0 1-.984-.984c-.144-.283-.198-.578-.222-.875C0 11.616 0 11.274 0 10.88V3.12c0-.395 0-.736.023-1.017.024-.297.078-.592.222-.875A2.25 2.25 0 0 1 1.23.245c.283-.144.578-.198.875-.222C2.384 0 2.726 0 3.12 0Zm-.916 1.52 1.442 1.184c1.366 1.123 2.732 2.246 4.1 3.367.09.074.135.111.185.126a.25.25 0 0 0 .136 0c.05-.015.095-.052.186-.126 1.367-1.121 2.733-2.244 4.1-3.367l1.44-1.184-.02-.002a12.753 12.753 0 0 0-.924-.018h-9.7a12.754 12.754 0 0 0-.945.02Z"
      fill="currentColor"
    />
  </svg>
);
const VerificationIcon = () => (
  <svg
    className="w-4 shrink-0 text-white/50 transition group-focus-within:text-white"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.481 12.804v-6.13L5.343 9.11c.376.238.707.447 1.077.532.324.075.66.075.985 0 .37-.085.7-.294 1.077-.532l3.862-2.435v1.547c0 .233 0 .349.037.44.05.122.146.22.267.27.091.038.206.038.436.038s.345 0 .436-.038a.496.496 0 0 0 .267-.27c.038-.092.038-.208.038-.44V6.048c0-.422 0-.794-.112-1.139a2.246 2.246 0 0 0-.473-.814c-.243-.267-.564-.448-.93-.654L9.4 1.797 7.97.988 7.917.96c-.2-.113-.424-.24-.675-.293a1.615 1.615 0 0 0-.66 0c-.252.052-.477.18-.676.293l-.051.03-1.43.808c-.97.548-1.94 1.097-2.911 1.644-.366.206-.687.387-.93.654a2.246 2.246 0 0 0-.473.814C-.001 5.254 0 5.626 0 6.048a2743.257 2743.257 0 0 1 0 6.785c0 .394 0 .734.023 1.014.024.295.077.589.22.871.212.422.552.765.97.98.28.144.572.197.865.221.277.023.614.023 1.004.023h3.09c.23 0 .345 0 .436-.038a.496.496 0 0 0 .267-.27c.037-.09.037-.207.037-.439s0-.348-.037-.44a.496.496 0 0 0-.267-.27c-.091-.037-.206-.037-.436-.037H3.11c-.427 0-.703 0-.913-.018-.201-.016-.276-.045-.312-.063a.744.744 0 0 1-.324-.327c-.019-.037-.046-.112-.063-.315-.017-.212-.018-.49-.018-.92ZM6.887 2.128c-.08.03-.156.075-.23.12l-.077.044-4.265 2.41c-.387.218-.524.302-.594.36l4.328 2.73c.502.316.606.37.7.392.107.025.22.025.328 0 .093-.021.197-.076.699-.392l4.328-2.73c-.07-.058-.207-.142-.594-.36l-4.265-2.41a4.11 4.11 0 0 1-.077-.045c-.074-.044-.15-.089-.23-.119a.147.147 0 0 0-.051 0Z"
      fill="currentColor"
    />
    <path
      d="M15.646 11.248c-.174-.152-.26-.229-.353-.26a.49.49 0 0 0-.377.025c-.088.044-.164.131-.316.306l-2.762 3.185c-.085.098-.128.147-.18.17a.245.245 0 0 1-.144.015c-.055-.011-.107-.05-.21-.129l-1.133-.857c-.184-.139-.276-.208-.371-.233a.49.49 0 0 0-.374.054c-.085.05-.154.143-.292.329-.138.186-.207.279-.231.374a.502.502 0 0 0 .053.378c.05.085.142.155.326.294l1.939 1.467c.206.156.31.234.42.256a.49.49 0 0 0 .29-.03c.103-.045.188-.143.358-.34l3.426-3.95c.152-.174.228-.261.259-.355a.502.502 0 0 0-.025-.38c-.044-.09-.13-.166-.303-.319Z"
      fill="currentColor"
    />
  </svg>
);
