import { useState, useRef, useEffect } from "react";
import chunk from "lodash/chunk";
import debounce from "lodash/debounce";
import { Transition } from "@headlessui/react";
import { useTranslation } from "next-i18next";
import Image from "next/image";

import Disclosure, { DisclosureButton } from "components/Disclosure/Disclosure";
import SlideIn from "components/Transitions/SlideIn";

export default function Bundle({
  productName,
  shows,
}: {
  productName: string;
  shows: Array<any> | null | undefined;
}) {
  const { t } = useTranslation("common");

  const [hovering, setHovering] = useState(null);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (window?.innerWidth < 768 && shows?.length > 3) {
      const debounced = debounce((e) => {
        let dimensions = scrollContainerRef?.current?.getBoundingClientRect();

        if (!dimensions) return;

        let top = dimensions.top - window.innerHeight;
        let bottom = dimensions.top + dimensions.height;
        let intersected = top + dimensions.height + 50 < 0 && bottom > 0;

        if (intersected) {
          scrollContainerRef?.current?.scroll({
            left: (((0 - top) / bottom) * dimensions.width) / 4,
            behavior: "smooth",
          });
        }
      }, 50);

      window.addEventListener("scroll", debounced);

      return () => window.removeEventListener("scroll", debounced);
    }
  });

  return (
    <SlideIn>
      <Disclosure defaultOpen={true}>
        <DisclosureButton>{t("in_the_bundle")}</DisclosureButton>
        <Transition
          enter="origin-top-left transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="origin-top-left transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel className="px-2 pt-4 pb-8">
            <div>
              <div className="relative">
                <div
                  ref={scrollContainerRef}
                  className="no-scrollbar relative flex shrink-0 items-center gap-2 overflow-x-auto pr-16 md:hidden"
                >
                  {shows.map((show: any, i) => (
                    <div
                      key={show.title + "-" + i}
                      className="relative inline-flex h-32 w-32 shrink-0 rounded-md bg-white/10 md:aspect-square md:h-auto md:w-full"
                      onMouseEnter={() => setHovering(show.title)}
                      onMouseLeave={() => setHovering(null)}
                    >
                      <Image
                        src={show.coverImageUrl}
                        layout="fill"
                        className="h-full w-full rounded-md"
                      />
                    </div>
                  ))}
                </div>
                <div className="hidden md:grid md:gap-4">
                  {chunk(shows, 3).map((showChunk: any, i) => (
                    <SlideIn key={"shows-" + i}>
                      <div className="grid grid-cols-3 gap-4">
                        {showChunk.map((show: any, i) => (
                          <div
                            key={show.title + "-desktop-" + i}
                            className="relative inline-flex h-32 w-32 shrink-0 rounded-md bg-white/10 md:aspect-square md:h-auto md:w-full"
                            onMouseEnter={() => setHovering(show.title)}
                            onMouseLeave={() => setHovering(null)}
                          >
                            <Image
                              src={show.coverImageUrl}
                              layout="fill"
                              className="h-full w-full rounded-md"
                            />
                          </div>
                        ))}
                      </div>
                    </SlideIn>
                  ))}
                </div>
                <div className="pointer-events-none absolute inset-y-0 right-0 h-full w-32 bg-gradient-to-r from-[#000]/0 to-[#000] md:hidden"></div>
              </div>
              <SlideIn>
                <div className="mt-8">
                  <h3 className="font-semibold text-white">{productName}</h3>
                  <ul className="mt-1 text-[13px] leading-6 text-white/70">
                    {shows.map((show: any, i) => (
                      <li
                        key={show.title + "-title-" + i}
                        className={`${hovering === show.title && "text-white"} transition`}
                      >
                        {show.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </SlideIn>
            </div>
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    </SlideIn>
  );
}
