import { useState, useEffect } from "react";
import { VariantProps } from "class-variance-authority";

import { buttonStyles } from "./Button";
import ButtonOrLink, { Props as ButtonOrLinkProps } from "./ButtonOrLink";

export interface Props extends ButtonOrLinkProps, VariantProps<typeof buttonStyles> {}

export default function PremiumButton({ className, importance, size, fullWidth, ...props }: Props) {
  return (
    <div
      className={`relative overflow-hidden isolate flex items-center p-[1px] rounded-2xl bg-gradient-to-b from-[#131619] via-[#0F1215] to-[#0E1114] shadow-btn-premium focus-within:ring-2 focus-within:ring-white/20 ${
        props.disabled ? "shadow-btn-dark-inactive" : ""
      }`}
    >
      <svg
        className="absolute transition animate-slide"
        width="246"
        height="203"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#a)">
          <path
            d="M134.985 85.068c50.641 38.409 86.125 76.888 79.255 85.947-6.87 9.058-53.493-14.735-104.134-53.144-50.642-38.409-86.125-76.889-79.255-85.947 6.87-9.058 53.493 14.735 104.134 53.144Z"
            fill="url(#b)"
            fillOpacity=""
          />
        </g>
        <defs>
          <linearGradient
            id="b"
            x1="30.851"
            y1="31.924"
            x2="214.24"
            y2="171.015"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDFDFF" />
            <stop offset="1" stopColor="#FDFDFF" stopOpacity=".5" />
          </linearGradient>
          <filter
            id="a"
            x="0"
            y="0"
            width="245.091"
            height="202.939"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_641_13315" />
          </filter>
        </defs>
      </svg>
      <ButtonOrLink
        className={"relative " + buttonStyles({ className, importance, size, fullWidth })}
        {...props}
      />
    </div>
  );
}
