import Row from "components/Row/Row";

type Props = {
  title: string;
  value: string;
};

const DetailRow = ({ title, value }: Props) => {
  return (
    <Row className="flex-col-reverse !items-start !gap-0 py-3">
      <dt className="text-[13px] leading-[24px] text-white/70">{title}</dt>
      <dd className="font-semibold leading-[24px] text-white">{value}</dd>
    </Row>
  );
};

export default DetailRow;
