import { Disclosure, Transition } from "@headlessui/react";
import Row from "components/Row/Row";
import { t } from "i18next";
import Plus from "public/assets/plus.svg";
import { ReactNode } from "react";

type Props = {
  question: string;
  children: ReactNode;
};

const FAQ = ({ question, children }: Props) => {
  return (
    <Row className="py-2" borderNone>
      <Disclosure as="div" className="w-full">
        {({ open }) => (
          <>
            <Disclosure.Button className="group flex w-full items-start justify-between overflow-hidden rounded py-1 text-left font-semibold transition focus:outline-none focus-visible:shadow-input-focus">
              <span className={`flex-1 ${!open && "truncate"}`}>{question}</span>
              <Plus
                className={`${
                  open && "rotate-45 text-white/90 group-hover:text-white"
                } shrink-0 text-white/50 transition group-hover:text-white/70`}
              />
            </Disclosure.Button>
            <Transition
              enter="origin-top-left transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="origin-top-left transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="py-2 pr-8 text-sm text-white/70 md:text-base md:leading-[24px]">
                <p className="text-white/70">{children}</p>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </Row>
  );
};

export default FAQ;
