type Props = {
  children: React.ReactNode;
  classNameWrapper?: string;
  className?: string;
  iconLeft?: React.ReactNode;
  borderNone?: boolean;
};

const Row = ({ children, classNameWrapper = "", className = "", iconLeft }: Props) => (
  <div className={`flex items-center ${classNameWrapper}`}>
    {!!iconLeft && iconLeft}
    <div className={`flex items-center w-full py-2 gap-4 ${className}`}>{children}</div>
  </div>
);

export default Row;
