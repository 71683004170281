import { Fragment } from "react";
import { Listbox as BaseListbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function Listbox({
  selected,
  onChange,
  options,
}: {
  selected: Object | string;
  onChange: Function;
  options: Array<Object> | Array<String>;
}) {
  return (
    <div className="w-full">
      <BaseListbox value={selected} onChange={onChange} by="id">
        <div className="relative mt-1">
          <BaseListbox.Button className="relative flex h-10 w-full cursor-default items-center rounded-[10px] bg-surface-800 py-2 pl-4 pr-10 text-left font-medium leading-6 text-white/90 shadow-input hover:shadow-input-hover focus:outline-none focus-visible:shadow-input-focus sm:text-sm sm:leading-6 md:w-fit">
            <span className="block truncate">{selected?.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </BaseListbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <BaseListbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-[10px] bg-surface-800 p-px py-1 text-base shadow-input focus:outline-none sm:text-sm">
              {options?.map((option, index) => (
                <BaseListbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 transition ${
                      active ? "bg-surface-700 text-white" : "text-white/70"
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"}`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-white/70">
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </BaseListbox.Option>
              ))}
            </BaseListbox.Options>
          </Transition>
        </div>
      </BaseListbox>
    </div>
  );
}
