import Image from "next/image";
import { useEffect, useCallback, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import Marquee from "react-fast-marquee";

import { formatRelativeDate, formatPlayerEpisodeTime } from "utils/datetime";

import Explicit from "public/assets/explicit.svg";
import { useTranslation } from "next-i18next";
import TooltipOnHover from "components/Tooltip/TooltipOnHover";

// Inspired by https://blog.logrocket.com/building-audio-player-react/#configuring-progress-bar-volume-slider for reference
export default function Player({
  activeEpisode,
  setActiveEpisode,
  previousEpisode,
  nextEpisode,
  autoplay,
  playbackSpeed,
  setPlaybackSpeed,
  handleIsPlaying,
}) {
  const wrapper = useRef(null);
  const player = useRef(null);
  const audio = useRef<HTMLAudioElement>(null);
  const scrubber = useRef(null);
  const playAnimationRef = useRef(null);

  const [initialized, setInitialized] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showMobilePlayer, setShowMobilePlayer] = useState(true);

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (keyCode === 37) {
      audio.current.currentTime -= 5;
    } else if (keyCode === 39) {
      audio.current.currentTime += 5;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    setInitialized(true);
  });

  useEffect(() => {
    if (audio.current) {
      audio.current.playbackRate = playbackSpeed;
    }
  }, [activeEpisode, playbackSpeed]);

  const { t, i18n } = useTranslation("common");

  const repeat = useCallback(() => {
    if (!scrubber.current) return;

    const currentTime = audio.current?.currentTime;
    setTimeProgress(currentTime);
    scrubber.current.value = currentTime;
    const scrubberTimeout = setTimeout(() => {
      wrapper?.current?.style.setProperty(
        "--range-progress",
        `${((scrubber.current?.value || 0) / duration) * 100}%`
      );
    }, 0);

    playAnimationRef.current = requestAnimationFrame(repeat);
    return () => clearTimeout(scrubberTimeout);
  }, [audio, duration, scrubber, setTimeProgress]);

  useEffect(() => {
    handleIsPlaying(playing);
    if (playing) {
      audio.current?.play();
    } else {
      audio.current?.pause();
    }

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [playing, audio, repeat, playAnimationRef]);

  const play = () => {
    setPlaying(true);
    audio.current.play();
  };

  const pause = () => {
    setPlaying(false);

    audio.current.pause();
  };

  const skipForward = () => {
    audio.current.currentTime += 30;
  };

  const skipBackward = () => {
    audio.current.currentTime -= 15;
  };

  const handleProgressChange = () => {
    audio.current.currentTime = scrubber.current.value;
  };

  const onLoadedMetadata = () => {
    const seconds = audio.current.duration;
    setDuration(seconds);
    scrubber.current.max = seconds;
  };

  const onEnded = () => {
    if (autoplay) {
      return nextEpisode();
    }

    audio.current.currentTime = 0;
    pause();
  };

  useEffect(() => {
    const handleKeydown = (e) => {
      if (
        e.code === "Space" &&
        (!["a", "button", "input", "select", "textarea", "radio"].includes(
          e.target.tagName.toLowerCase()
        ) ||
          player.current?.contains(e.target))
      ) {
        e.preventDefault();
        playing ? pause() : play();
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => document.removeEventListener("keydown", handleKeydown);
  });

  const close = () => {
    playing && pause();

    setShowMobilePlayer(false);

    setActiveEpisode(null);
  };

  useEffect(() => {
    if (playing) {
      setShowMobilePlayer(true);
    }
  }, [playing]);

  let previousActiveEpisode = useRef(null);

  useEffect(() => {
    if (activeEpisode !== null && !showMobilePlayer) {
      setShowMobilePlayer(true);
      setPlaying(true);
    }
    let episodeChanged =
      activeEpisode &&
      JSON.stringify(activeEpisode) !== JSON.stringify(previousActiveEpisode.current);

    if (previousActiveEpisode.current !== null && episodeChanged) {
      setPlaying(true);
    }

    if (episodeChanged) {
      previousActiveEpisode.current = activeEpisode;
      setShowMobilePlayer(true);
    }
  }, [activeEpisode]);

  return (
    <div ref={wrapper} className="player w-full">
      {/* Desktop */}
      <div className="relative hidden md:flex md:h-full md:min-h-screen md:w-full md:items-center md:px-4">
        {/* <div className="absolute inset-0 overflow-hidden">
            <div
              className="absolute inset-0 h-full min-h-[1024px] w-full min-w-[1024px] bg-center bg-no-repeat opacity-[0.1] blur-[60px]"
              style={{
                backgroundImage: activeEpisode ? `url(${activeEpisode?.image_url})` : '#',
                backgroundSize: "1024px auto",
              }}
            >
              <div
                className="absolute inset-0 h-full w-full mix-blend-screen"
                style={{
                  background:
                    "linear-gradient(172.29deg, #555360 27.02%, rgba(85, 83, 96, 0.48) 47.76%, rgba(85, 83, 96, 0) 74.01%)",
                }}
              />
            </div>
          </div> */}
        <div ref={player} className="relative w-full">
          <div className="pointer-events-auto relative mx-auto w-full max-w-[384px] overflow-hidden rounded-3xl bg-secondary-800 shadow-player">
            <div className="relative z-0 aspect-square w-full overflow-hidden">
              <div
                style={{ backgroundImage: `url(${activeEpisode?.image_url})` }}
                className="absolute inset-0 h-full w-full bg-secondary-700 bg-contain bg-top"
              >
                {/* Bottom blur */}
                <div
                  className="absolute inset-0 h-full w-full bg-contain bg-top blur"
                  style={{
                    backgroundImage: "inherit",
                    WebkitMaskImage: "linear-gradient(to bottom, transparent 50%, black)",
                    maskImage: "linear-gradient(to bottom, transparent 50%, black)",
                  }}
                ></div>
              </div>
              {/* Bottom gradient */}
              <div className="absolute inset-x-0 bottom-0 h-1/2 w-full bg-gradient-to-b from-secondary-800/0 to-secondary-800"></div>
            </div>
            <div className="relative -mt-24 p-6 lg:px-10">
              <div className="h-12 text-center">
                {activeEpisode?.title.length >= 30 ? (
                  <Marquee
                    speed={20}
                    delay={3}
                    pauseOnHover={true}
                    className="whitespace-no-wrap font-semibold leading-6 text-white"
                  >
                    <div className="pr-12">{activeEpisode?.title || " "}</div>
                  </Marquee>
                ) : (
                  <h2 className="font-semibold leading-6 text-white">
                    {activeEpisode?.title || " "}
                  </h2>
                )}
                {activeEpisode ? (
                  <p className="truncate whitespace-nowrap text-[13px]  leading-6 text-white/70">
                    {activeEpisode && formatRelativeDate(activeEpisode?.published_at)}
                    <span className="font-light"> &bull;</span> {activeEpisode?.metadata}
                    {activeEpisode?.explicit && (
                      <>
                        <span className="font-light"> &bull; </span>
                        <Explicit className="inline-block fill-white/70 pt-[1px] align-baseline" />
                      </>
                    )}
                  </p>
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>
              <div className="mt-6">
                <span className="w-full">
                  <input
                    ref={scrubber}
                    type="range"
                    className="scrubber w-full focus:outline-none"
                    defaultValue="0"
                    onChange={handleProgressChange}
                  />
                </span>
              </div>
              <div className="mt-3 flex select-none items-center justify-between">
                <span className="relative w-[72px] font-digital text-sm font-black">
                  <span className="absolute inset-0 text-[#5CE7FF0F]/10">88:88:88</span>
                  <div
                    style={{ textShadow: "0px 0px 8px rgba(232, 248, 255, 0.2)" }}
                    className="text-white/90"
                  >
                    {formatPlayerEpisodeTime(timeProgress, duration)}
                  </div>
                </span>
                <TooltipOnHover
                  text={t("player_speed")}
                  className="relative w-[120px] cursor-pointer  capitalize"
                >
                  <div
                    onClick={() => {
                      if (playbackSpeed === 1) {
                        setPlaybackSpeed(1.5);
                      } else if (playbackSpeed === 1.5) {
                        setPlaybackSpeed(2);
                      } else {
                        setPlaybackSpeed(1);
                      }
                    }}
                    className="flex justify-center font-digital text-sm font-black"
                  >
                    {/* <span className="absolute inset-0 w-full text-center text-[#5CE7FF0F]/10">
                      888888888888888
                    </span> */}
                    <div
                      style={{ textShadow: "0px 0px 8px rgba(133, 209, 255, 0.2)" }}
                      className="flex  "
                    >
                      {/* <span className="opacity-0">000</span> */}
                      <div className="flex gap-1.5">
                        <div className={`${playbackSpeed === 1 ? "text-[#85D1FF]" : "opacity-40"}`}>
                          1X
                        </div>
                        <div
                          className={`${playbackSpeed === 1.5 ? "text-[#85D1FF]" : "opacity-40"}`}
                        >
                          1.5X
                        </div>
                        <div className={`${playbackSpeed === 2 ? "text-[#85D1FF]" : "opacity-40"}`}>
                          2X
                        </div>
                        {/* <div>{playbackSpeed}X</div> */}
                      </div>
                    </div>
                  </div>
                </TooltipOnHover>
                <span className="relative w-[72px] font-digital text-sm font-black">
                  <span className="absolute inset-0 text-right text-[#5CE7FF0F]/10">88:88:88</span>
                  <div
                    style={{ textShadow: "0px 0px 8px rgba(232, 248, 255, 0.2)" }}
                    className="text-right text-white/90"
                  >
                    -{formatPlayerEpisodeTime(duration - timeProgress)}
                  </div>
                </span>
              </div>
              <div className="mt-5 flex items-center justify-center gap-8">
                <button
                  onClick={previousEpisode}
                  className="inline-flex h-6 w-6 shrink-0 rounded-full transition hover:opacity-80 focus:outline-none focus:ring-0 focus-visible:shadow-input-focus disabled:opacity-30"
                >
                  <span className="sr-only">Go to previous track</span>
                  <Image
                    src="/assets/skip.svg"
                    width={24}
                    height={24}
                    className="h-6 w-6 rotate-180"
                  />
                </button>
                <Button onClick={skipBackward}>
                  <span className="sr-only">Go back 15 seconds</span>
                  <Image src="/assets/back-15.svg" width={24} height={24} className="h-6 w-6" />
                </Button>
                <Button onClick={() => (playing ? pause() : play())}>
                  <span className="sr-only">{playing ? "Pause" : "Play"}</span>
                  {playing ? (
                    <Image src="/assets/pause.svg" width={24} height={24} className="h-6 w-6" />
                  ) : (
                    <Image src="/assets/play.svg" width={24} height={24} className="h-6 w-6" />
                  )}
                </Button>
                <Button onClick={skipForward}>
                  <span className="sr-only">Go forward 30 seconds</span>
                  <Image src="/assets/forward-30.svg" width={24} height={24} className="h-6 w-6" />
                </Button>
                <button
                  onClick={nextEpisode}
                  className="inline-flex h-6 w-6 shrink-0 rounded-full transition hover:opacity-80 focus:outline-none focus:ring-0 focus-visible:shadow-input-focus disabled:opacity-30"
                >
                  <span className="sr-only">Go to next track</span>
                  <Image src="/assets/skip.svg" width={24} height={24} className="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="fixed inset-x-0 bottom-0 z-50 mx-auto w-full max-w-xl overflow-hidden px-4 py-6 md:hidden">
        <Transition
          show={showMobilePlayer}
          enter="transition ease-linear duration-200"
          enterFrom="opacity-0 translate-y-full"
          enterTo="opacity-100"
          leave="transition ease-linear duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 translate-y-full"
          as="div"
        >
          <div className="rounded-full bg-secondary-800 p-1 shadow-player">
            <div className="relative overflow-hidden rounded-full">
              <div
                className="absolute inset-y-0 left-0 w-[var(--range-progress)] bg-[rgba(232,248,255,0.04)]"
                style={{
                  boxShadow:
                    "0px 0px 0px 1px rgba(0, 0, 0, 0.16), inset 0px 1px 0px rgba(232, 248, 255, 0.02)",
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-b from-white/[0.02] via-white/[0.04] to-white/[0.02]"></div>
              </div>
              <div className="relative flex items-center justify-between gap-4 p-1">
                <Button onClick={() => (playing ? pause() : play())}>
                  <span className="sr-only">{playing ? "Pause" : "Play"}</span>
                  {playing ? (
                    <Image src="/assets/pause.svg" width={24} height={24} className="h-6 w-6" />
                  ) : (
                    <Image src="/assets/play.svg" width={24} height={24} className="h-6 w-6" />
                  )}
                </Button>
                <div className="flex-1 overflow-hidden text-center">
                  {activeEpisode?.title.length >= 30 ? (
                    <Marquee
                      speed={20}
                      delay={3}
                      pauseOnHover={true}
                      className="text-sm font-semibold text-white"
                    >
                      <div className="pr-12">{activeEpisode?.title}</div>
                    </Marquee>
                  ) : (
                    <div className="text-center text-sm font-semibold text-white">
                      {activeEpisode?.title}
                    </div>
                  )}
                  <p
                    className={`flex text-xs text-white/70 ${
                      activeEpisode?.title.length < 30 && "justify-center text-center"
                    }`}
                  >
                    <span className="block truncate">
                      {activeEpisode &&
                        formatRelativeDate(activeEpisode?.published_at, i18n.language)}
                      <span className="font-light"> &bull; </span>
                      {activeEpisode?.metadata}
                    </span>
                    {activeEpisode?.explicit && (
                      <>
                        <span className="font-light"> &bull; </span>
                        <Explicit className="inline-block fill-white/70 pt-[1px] align-baseline" />
                      </>
                    )}
                  </p>
                </div>
                <div className="mr-1">
                  <Button onClick={close} small>
                    <span className="sr-only">Close</span>
                    <Image
                      src="/assets/player-close.svg"
                      width={24}
                      height={24}
                      className="h-6 w-6"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>

      {/* Actual player */}
      {initialized && (
        <audio
          ref={audio}
          src={activeEpisode?.media_url}
          controls
          className="hidden"
          onLoadedMetadata={onLoadedMetadata}
          onEnded={onEnded}
        />
      )}
    </div>
  );
}

const Button = ({ children, onClick, small = false }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        small ? "h-8 w-8" : "h-10 w-10"
      } relative inline-flex shrink-0 items-center justify-center rounded-full transition hover:opacity-80 focus:outline-none focus:ring-0 focus-visible:shadow-input-focus disabled:opacity-30`}
    >
      <div
        className="absolute inset-0 flex items-center justify-center rounded-full "
        style={{
          background: "#16191c",
          boxShadow:
            "rgba(0, 0, 0, 0.24) 0px 0px 0px 1px, rgba(0, 0, 0, 0.24) 0px 1px 2px 0px, rgba(0, 0, 0, 0.24) 0px 2px 4px 0px, rgba(0, 0, 0, 0.24) 0px 4px 8px 0px, rgba(0, 0, 0, 0.16) 0px -1px 1px 0px inset, rgba(255, 255, 255, 0.06) 0px 1px 1px 0px inset",
        }}
      >
        <div
          className={`${
            small ? "h-[30px] w-[30px]" : "h-[38px] w-[38px]"
          } overflow-hidden rounded-full`}
          style={{
            backgroundImage: "linear-gradient(rgb(12, 15, 18) 0%, rgb(22, 25, 28) 100%)",
          }}
        ></div>
      </div>
      <div className="relative flex h-full w-full items-center justify-center">{children}</div>
    </button>
  );
};
