import { ComponentProps } from "react";
import Link from "next/link";

type ButtonOrLinkProps = ComponentProps<"button"> & ComponentProps<"a">;

export interface Props extends ButtonOrLinkProps {
  smoothScroll?: boolean;
}

/**
 * This is a base component that will render either a button or a link,
 * depending on the props that are passed to it. The link rendered will
 * also correctly get wrapped in a next/link component to ensure ideal
 * page-to-page transitions.
 */
export default function ButtonOrLink({ href, smoothScroll = false, ...props }: Props) {
  const isLink = typeof href !== "undefined";
  const ButtonOrLink = isLink ? "a" : "button";

  let content = <ButtonOrLink {...props} />;

  if (!smoothScroll && isLink) {
    return <Link href={href}>{content}</Link>;
  }

  if (smoothScroll) {
    const scroll = (e) => {
      if (smoothScroll) {
        e.preventDefault();
        document.querySelector(href as string)?.scrollIntoView({ behavior: "smooth" });
      }
    };

    return <ButtonOrLink {...props} onClick={scroll} />;
  }

  return content;
}
