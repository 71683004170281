import { Trans, useTranslation } from "next-i18next";

import Button from "components/Button/Button";
import ButtonOrLink from "components/Button/ButtonOrLink";
import Dialog from "components/Dialog/Dialog";
import RadioGroup, { RadioGroupItem } from "components/RadioGroup/RadioGroup";
import { useRouter } from "next/router";

export default function ExpiredSubscriptionDialog({
  isOpen,
  setIsOpen,
  selectedPaymentOption,
  setSelectedPaymentOption,
  purchaseOptions,
  productName,
  trialDays,
  token,
}: any) {
  const { t } = useTranslation("common");
  const router = useRouter();

  const openStripe = () => {
    router.push(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/premium/purchase?token=${token}&id=${selectedPaymentOption}`
    );
  };

  return (
    <Dialog
      show={isOpen || false}
      title={t("expired_subscription_header")}
      image="/assets/modal-juice.png"
      onClose={() => setIsOpen()}
    >
      <p className="mt-4 text-center text-white/70">
        {t("expired_subscription_description", { title: productName })}
      </p>
      <section className="mt-6">
        <RadioGroup
          name={"select-plan"}
          defaultValue={selectedPaymentOption}
          onChange={setSelectedPaymentOption}
          label={t("choose_plan")}
        >
          {purchaseOptions.map((option) => (
            <RadioGroupItem
              id={option.identifier}
              key={option.identifier}
              value={option.identifier}
            >
              <span className="">{t(option.name)}</span>
              {/* <span className="ml-3 rounded-lg px-1.5 py-1 text-xs text-white/50 ring-2 ring-inset ring-white/10 transition ui-checked:bg-gradient-to-b ui-checked:from-[#3377FF] ui-checked:to-[#0055FF] ui-checked:text-white ui-checked:ring-transparent">
-              -15%
-            </span> */}
              <span className="ml-auto text-white/50 transition group-hover:!text-white ui-checked:text-white/90">
                {`${t("displayPrice", {
                  price: option.price.price,
                })} ${option.price.currency === "SEK" ? "kr" : option.price.currency} `}
                <span className="text-sm font-medium text-white/50 transition group-hover:text-white ui-checked:text-white/90">
                  /{t(option.billingFrequency.toLowerCase())}
                </span>
              </span>
            </RadioGroupItem>
          ))}
        </RadioGroup>
        {/* TODO: redirect to Stripe */}
        <Button href="#" importance="primary" className="mt-6" onClick={() => openStripe()}>
          {t("start_subscription")}
        </Button>
        <Button onClick={() => setIsOpen(false)} importance="secondary" className="mt-4" fullWidth>
          {t("Cancel")}
        </Button>
        <p className="mt-6 text-center text-xs text-white/70">
          {t("start_trial_description", {
            title: productName,
            trialDays,
            price: purchaseOptions.find(({ identifier }) => identifier == selectedPaymentOption)
              ?.price.displayPrice,
            interval:
              selectedPaymentOption == purchaseOptions[0].identifier ? t("month") : t("year"),
          })}
        </p>
      </section>
      <p className="mt-4 text-center text-xs text-white/70">
        <Trans i18nKey="contact_support_team">
          Please contact our{" "}
          <ButtonOrLink
            href="mailto:help@podspace.com"
            className="rounded text-white/90 underline focus:shadow-input-focus focus:outline-none focus:ring-0"
          >
            Support Team
          </ButtonOrLink>{" "}
          for any questions.
        </Trans>
      </p>
    </Dialog>
  );
}
